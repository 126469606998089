/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import React from "react";
import MDButton from "../../MDButton";

function List({ items, columns, action }) {

  const renderItems = () => {
      if(columns === 1){
          return items.map(({ color, icon, name, description, route }, key) => (
              <MDBox
                  key={name}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  borderRadius="lg"
                  py={1}
                  pr={2}
                  mb={items.length - 1 === key ? 0 : 1}
              >
                  <MDBox display="flex" alignItems="center">
                      <MDBox
                          display="grid"
                          alignItems="center"
                          justifyContent="center"
                          bgColor={color}
                          borderRadius="lg"
                          shadow="md"
                          color="white"
                          width="2rem"
                          height="2rem"
                          mr={2}
                          variant="gradient"
                          fontSize="0.875rem"
                      >
                          <Icon
                              sx={{
                                  display: "grid",
                                  placeItems: "center",
                              }}
                          >
                              {icon}
                          </Icon>
                      </MDBox>
                      <MDBox display="flex" flexDirection="column">
                          <MDTypography variant="button" color={color} fontWeight="medium" gutterBottom>
                              {name}
                          </MDTypography>
                          <MDTypography variant="caption" color="text">
                              {description}
                          </MDTypography>
                      </MDBox>
                  </MDBox>
              </MDBox>
          ));
      } else {
          return <Grid container spacing={3}>
              {
                  items.map(({ id, color, icon, name, description, route }, key) => (
                      <Grid
                          key={name}
                          item
                          borderRadius="lg"
                          py={1}
                          pr={2}
                          mb={items.length - 1 === key ? 0 : 1}
                          xs={12}
                          lg={12 / columns}
                      >
                          <MDBox display="flex" alignItems="center">
                              <MDBox
                                  display="grid"
                                  alignItems="center"
                                  justifyContent="center"
                                  flexShrink={0}
                                  bgColor={color}
                                  borderRadius="lg"
                                  shadow="md"
                                  color="white"
                                  width="2rem"
                                  height="2rem"
                                  mr={2}
                                  variant="gradient"
                                  fontSize="0.875rem"
                              >
                                  <Icon
                                      sx={{
                                          display: "grid",
                                          placeItems: "center",
                                      }}
                                  >
                                      {icon}
                                  </Icon>
                              </MDBox>
                              <MDBox display="flex" flexDirection="column">
                                  <MDBox display="flex" flexDirection="row" alignItems="center">
                                      <MDTypography variant="button" color={color} fontWeight="medium">
                                          {name}
                                      </MDTypography>
                                      {
                                          action !== undefined &&
                                              <MDButton iconOnly color="error" variant="text" onClick={() => action(id)} sx={{padding: 0}}><Icon>delete</Icon></MDButton>
                                      }
                                  </MDBox>
                                  <MDTypography variant="caption" color="text">
                                      {description}
                                  </MDTypography>
                              </MDBox>
                          </MDBox>
                      </Grid>
                  ))
              }
          </Grid>
      }
  };

  return (
      <MDBox display="flex" flexDirection="column" p={0} m={0}>
          {renderItems()}
      </MDBox>
  );
}

// Typechecking props for the CategoriesList
List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default List;
