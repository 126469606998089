/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "adventue-profile-form",
  formField: {
    name: {
      name: "name",
      label: "Adventure Name",
      type: "text",
      errorMsg: "Adventure name is required."
    },
    short_description: {
      name: "short_description",
      label: "Short Description",
      type: "text",
      errorMsg: "Short description is required."
    },
    description: {
      name: "description",
      label: "Full Description",
      type: "text",
      errorMsg: "Full description is required."
    }
  },
};

export default form;
