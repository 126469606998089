import React, {useEffect, useState} from "react";
import axios from "axios";
import {API_URL} from "../../../../../config";
import useReviews from "./useReviews";
import MDBox from "../../../../../components/MDBox";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import MDPagination from "../../../../../components/MDPagination";
import Icon from "@mui/material/Icon";
import MDSnackbar from "../../../../../components/MDSnackbar";

const Reviews = ({id}) => {

    const {isLoading, snackbarConfig, show, renderReviews, onNextPageClick, onPreviousPageClick, toggleSnackbar} = useReviews(id);

    return <MDBox mb={3}>
        {
            isLoading ?
                <MDBox mt={2} mx={2} p={10} display="flex" justifyContent="center"
                       alignItems="center">
                    <CircularProgress color="secondary" style={{overflow: 'hidden'}}/>
                </MDBox> :
                <MDBox>
                    { renderReviews() }
                    <MDPagination>
                        <MDPagination item onClick={onPreviousPageClick}>
                            <Icon>keyboard_arrow_left</Icon>
                        </MDPagination>
                        <MDPagination item onClick={onNextPageClick}>
                            <Icon>keyboard_arrow_right</Icon>
                        </MDPagination>
                    </MDPagination>
                </MDBox>
        }
        <MDSnackbar
            color={snackbarConfig.color}
            icon={snackbarConfig.icon}
            title={snackbarConfig.title}
            content={snackbarConfig.message}
            dateTime=""
            autoHideDuration={3000}
            open={show}
            close={toggleSnackbar}
        />
    </MDBox>
};

export default Reviews;
