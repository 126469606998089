/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import MDBox from "../../../../../components/MDBox";
import FormField from "../../../../../components/FormField";
import React from "react";
// Material Dashboard 2 PRO React components


function EditItemForm({formData}) {

    const {formField, values, errors, touched} = formData;

    const {name, description, price} = formField;
    const {
        name: nameV,
        description: descriptionV,
        price: priceV
    } = values;

    return (
        <MDBox>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={name.type}
                        label={name.label}
                        name={name.name}
                        value={nameV}
                        error={errors.name && touched.name}
                        success={nameV && nameV.length > 0 && !errors.name}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={price.type}
                        label={price.label}
                        name={price.name}
                        value={priceV}
                        error={errors.price && touched.price}
                        success={priceV && priceV.length > 0 && !errors.price}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={description.type}
                        label={description.label}
                        name={description.name}
                        value={descriptionV}
                        error={errors.description && touched.description}
                        success={descriptionV && descriptionV.length > 0 && !errors.description}
                    />
                </Grid>
            </Grid>
        </MDBox>
    );
}

export default EditItemForm;
