import Card from "@mui/material/Card";
import React from "react";
import MDBox from "../../MDBox";
import MDTypography from "../../MDTypography";
import ReviewCell from "../../ReviewCell";
import MDAvatar from "../../MDAvatar";

const ReviewCard = ({title, content, user, score, createdAt}) => {

    return <Card sx={{marginBottom: 3}}>
        <MDBox p={3}>
            <MDBox display="flex" justifyContent="space-between" alignItems="flex-start">
                <MDTypography variant="h5">{title}</MDTypography>
                <ReviewCell rating={score} />
            </MDBox>
            <MDTypography variant="body2">{content}</MDTypography>
            <MDBox mt={2}>
                <MDTypography variant="h6">{user}</MDTypography>
                <MDTypography variant="button">{createdAt}</MDTypography>
            </MDBox>
        </MDBox>
    </Card>;
};

export default ReviewCard;
