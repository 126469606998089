import React, {useEffect} from 'react';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {useSelector} from "react-redux";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import MDAvatar from "../../../../components/MDAvatar";
import moment from "moment";

const Messages = ({messages, sx}) => {

    const authState = useSelector((state) => state.authReducer);

    const messagesEndRef = React.useRef(null);

    useEffect(() => {
        messagesEndRef.current.scrollIntoView();
    }, [messages]);

    const renderAvatar = (name) => {
        let avatarInitials = name.split(' ');

        if (avatarInitials.length >= 2) {
            return <MDAvatar bgColor="secondary"
                                       size="sm">{avatarInitials[0][0].toUpperCase()}{avatarInitials[1][0].toUpperCase()}</MDAvatar>;
        } else {
            return <MDAvatar bgColor="secondary" size="sm">{avatarInitials[0][0].toUpperCase()}</MDAvatar>;
        }
    };

    return (
        <MDBox sx={sx} mx={1} mt={1} p={3} bgColor="#FAFAFA">
            {
                messages.length === 0 ?
                    <MDTypography variant="body2" align="center">No messages yet, send one!</MDTypography> :
                    messages.map((message, index) => (
                        <MDBox key={message.id} mt={2} display="flex" flexDirection="row" justifyContent={message.user_id === authState.userId ? 'flex-end' : 'flex-start'} alignItems="center">
                            {
                                message.user_id !== authState.userId &&
                                <MDBox mr={2}>
                                    {renderAvatar(message.user.name)}
                                </MDBox>
                            }
                            <MDBox key={message.id} sx={{maxWidth: '60%', backgroundColor: 'white', padding: 2, boxShadow: 1, borderRadius: 2}}>
                                <MDTypography variant="body2">{message.message}</MDTypography>
                                <MDTypography variant="caption" mt={1} fontWeight="bold" style={{display: 'block'}}>{moment(message.created_at).format('YYYY-MM-DD HH:mm:ss')}</MDTypography>
                            </MDBox>
                            {
                                message.user_id === authState.userId &&
                                <MDBox ml={2}>
                                    {renderAvatar(message.user.name)}
                                </MDBox>
                            }
                        </MDBox>
                    ))
            }
            <div ref={messagesEndRef} />
        </MDBox>
    );
};

export default Messages;
