import React, {useEffect, useRef, useState} from "react";

const Map = ({center, zoom, children}) => {
    const ref = useRef();
    const [map, setMap] = useState();

    useEffect(() => {
        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, {center,
                zoom,}));
        }
    }, [ref, map]);

    return (
        <>
            <div ref={ref} id="map" style={{height: 500, width: '100%'}} />
            {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                    // set the map prop on the child component
                    return React.cloneElement(child, { map });
                }
            })}
        </>
    );
};

export default Map;
