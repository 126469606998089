/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import BaseLayout from "./components/BaseLayout";
import Sidenav from "./components/Sidenav";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {useSelector} from "react-redux";
import {API_URL} from "../../config";
import Header from "./components/Header";
import moment from "moment";
import BasicInfo from "./components/BasicInfo";
import ChangePassword from "./components/ChangePassword";
import DeleteAccount from "./components/DeleteAccount";

// Settings page components

function Profile() {

    const authState = useSelector((state) => state.authReducer);

    const [profile, setProfile] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        const init = async () => {
            await getProfileData(`${API_URL}/users/profile`);
        };

        init();
    }, []);

    const getProfileData = async (url) => {

        setIsLoading(true);

        const response = await axios.get(url);

        setProfile(response.data);

        setIsLoading(false);
    };

    return (
        <BaseLayout>
            <MDBox mt={4}>
                {
                    isLoading !== true &&
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={3}>
                            <Sidenav/>
                        </Grid>
                        <Grid item xs={12} lg={9}>
                            <MDBox mb={3}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Header title={profile.name} subtitle={`Joined at ${moment(profile.created_at).format('MMM DD, YYYY')}`}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <BasicInfo/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ChangePassword/>
                                    </Grid>
                                    {/*{*/}
                                    {/*    authState.roleId !== 1 &&*/}
                                    {/*    <Grid item xs={12}>*/}
                                    {/*        <BankDetails/>*/}
                                    {/*    </Grid>*/}
                                    {/*}*/}
                                    <Grid item xs={12}>
                                        <DeleteAccount/>
                                    </Grid>
                                </Grid>
                            </MDBox>
                        </Grid>
                    </Grid>
                }
            </MDBox>
        </BaseLayout>
    );
}

export default Profile;
