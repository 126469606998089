/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import BaseLayout from "./components/BaseLayout";
import React, {useEffect, useState} from "react";
import {useParams} from 'react-router-dom';
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import breakpoints from "assets/theme/base/breakpoints";
import Profile from "./components/Profile/Profile";
import Reviews from "./components/Reviews/Reviews";
import Reservations from "./components/Reservations/Reservations";
import {useSelector} from "react-redux";

function TabPanel({ children, value, index, ...other }) {

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                children
            )}
        </div>
    );
}

function AdventureDetails() {

    const { id } = useParams();

    const authState = useSelector((state) => state.authReducer);

    const [tabsOrientation, setTabsOrientation] = useState("horizontal");
    const [tabValue, setTabValue] = useState(0);

    useEffect(() => {
        // A function that sets the orientation state of the tabs.
        function handleTabsOrientation() {
            return window.innerWidth < breakpoints.values.sm
                ? setTabsOrientation("vertical")
                : setTabsOrientation("horizontal");
        }

        /**
         The event listener that's calling the handleTabsOrientation function when resizing the window.
         */
        window.addEventListener("resize", handleTabsOrientation);

        // Call the handleTabsOrientation function to set the state with the initial value.
        handleTabsOrientation();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleTabsOrientation);
    }, [tabsOrientation]);

    const handleSetTabValue = (event, newValue) => setTabValue(newValue);

    return (
        <BaseLayout>
            <Grid container>
                <Grid item xs={12} sm={8} lg={4}>
                    <AppBar position="static">
                        <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                            <Tab label="Profile" />
                            <Tab label="Reviews" />
                            {
                                authState.roleId === 1 &&
                                <Tab label="Reservations" />
                            }
                        </Tabs>
                    </AppBar>
                </Grid>
            </Grid>
            <MDBox mt={4}>
                <TabPanel value={tabValue} index={0}>
                    <Profile id={authState.roleId === 1 ? id : authState.adventureId} />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <Reviews id={authState.roleId === 1 ? id : authState.adventureId} />
                </TabPanel>
                {
                    authState.roleId === 1 &&
                    <TabPanel value={tabValue} index={2}>
                        <Reservations id={id}/>
                    </TabPanel>
                }
            </MDBox>
        </BaseLayout>
    );
}

export default AdventureDetails;
