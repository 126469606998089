import MDBox from "../../../../../components/MDBox";
import Card from "@mui/material/Card";
import LinearProgress from "@mui/material/LinearProgress/LinearProgress";
import MUIDataTable from "mui-datatables";
import MDTypography from "../../../../../components/MDTypography";
import MDSnackbar from "../../../../../components/MDSnackbar";
import React from "react";
import useReservations from "./useReservations";

const Reservations = ({id}) => {

    const {isLoading, options, columns, data, show, snackbarConfig, toggleSnackbar} = useReservations(id);

    return <>
        <MDBox pt={2} pb={3}>
            <MDBox mb={3}>
                <Card>
                    {
                        isLoading ?
                            <MDBox mt={2} mx={2}>
                                <LinearProgress color="secondary" style={{overflow: 'hidden'}}/>
                            </MDBox> :
                            null
                    }
                    <MUIDataTable
                        title={
                            <MDBox p={3} lineHeight={1}>
                                <MDTypography variant="h5" fontWeight="medium">
                                    Reservations
                                </MDTypography>
                                <MDTypography variant="button" color="text">
                                    View all registered reservations.
                                </MDTypography>
                            </MDBox>
                        }
                        data={data}
                        columns={columns}
                        options={options}
                    />
                </Card>
            </MDBox>
        </MDBox>
        <MDSnackbar
            color={snackbarConfig.color}
            icon={snackbarConfig.icon}
            title={snackbarConfig.title}
            content={snackbarConfig.message}
            dateTime=""
            autoHideDuration={3000}
            open={show}
            close={toggleSnackbar}
        />
    </>
};

export default Reservations;
