import form from "./schemas/form";
import validations from "./schemas/validations";
import {useSelector} from "react-redux";
import {useState} from "react";
import axios from "axios";
import {API_URL} from "../../../config";

const useNewAmenity = (id) => {

    const {formId, formField} = form;
    const currentValidation = validations;

    const authState = useSelector((state) => state.authReducer);

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    const handleSubmit = async (values, actions) => {

        try {

            let data = {...values};

            data.adventure_id = authState.roleId === 1 ? id : authState.adventureId;

            // Enviar datos a API
            await axios.post(
                `${API_URL}/amenities`,
                data
            );

            actions.resetForm();

            setSnackbarConfig({
                message: 'Amenity saved successfully',
                icon: 'notifications',
                title: 'Amenities',
                color: 'secondary'
            });

            setShow(true);

        } catch (error) {

            setSnackbarConfig({
                message: error.response.data.message,
                icon: 'cancel',
                title: 'Amenities',
                color: 'warning'
            });

            setShow(true);

        }

        actions.setSubmitting(false);

    };

    return {
        formId,
        formField,
        currentValidation,
        snackbarConfig,
        show,
        handleSubmit,
        toggleSnackbar
    }
};

export default useNewAmenity;
