import MDTypography from "../../../../../components/MDTypography";
import List from "../../../../../components/Lists/List";
import Card from "@mui/material/Card";
import React, {useRef, useState} from "react";
import MDBox from "../../../../../components/MDBox";
import Grid from "@mui/material/Grid";
import MDButton from "../../../../../components/MDButton";
import {useNavigate} from "react-router-dom";
import {API_URL, APP_URL} from "../../../../../config";
import axios from "axios";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import LinearProgress from "@mui/material/LinearProgress";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import MDSnackbar from "../../../../../components/MDSnackbar";

const LinearProgressWithLabel = (props) => {
    return (
        <MDBox sx={{display: 'flex', alignItems: 'center'}}>
            <MDBox sx={{width: '100%', mr: 1}}>
                <LinearProgress variant="determinate" {...props} />
            </MDBox>
            <MDBox sx={{minWidth: 40}}>
                <MDTypography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</MDTypography>
            </MDBox>
        </MDBox>
    );
}

const Gallery = ({adventureId, images}) => {

    let navigate = useNavigate();

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    const [uploadProgress, setUploadProgress] = useState(0);
    const [photos, setPhotos] = useState(images);

    const fileInputRef = useRef(null);

    const handleImageButtonClick = () => {
        fileInputRef.current.click();
    };

    const validationSchema = Yup.object().shape({
        image: Yup.mixed()
            .test(
                'fileSize',
                'Image too large',
                (value) => value && value.size <= 4000000
            )
            .test(
                'fileType',
                'Unsupported file type',
                (value) => value && ['image/jpeg', 'image/jpg', 'image/png'].includes(value.type)
            )
    });

    const handleImageDelete = async (imageId) => {

        try {

            const response = await axios.delete(`${API_URL}/adventures/${adventureId.id}/images/${imageId}`);

            let newPhotos = [...photos];
            let index = newPhotos.findIndex(photo => photo.id === imageId);

            newPhotos.splice(index, 1);

            setPhotos(newPhotos);

            setSnackbarConfig({
                message: "Photo deleted successfully!",
                icon: 'notifications',
                title: 'Gallery',
                color: 'secondary'
            });

            setShow(true);

        } catch (error) {

            setSnackbarConfig({
                message: "Something went wrong deleting the photo",
                icon: 'cancel',
                title: 'Gallery',
                color: 'warning'
            });

            setShow(true);
        }

    };

    const handleSubmit = async (values, {setSubmitting, setTouched}) => {

        const imageFile = values.image;

        try {
            const formData = new FormData();
            formData.append('image', imageFile);

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: (progressEvent) => {
                    const {loaded, total} = progressEvent;
                    const progress = Math.round((loaded * 100) / total);
                    setUploadProgress(progress);
                }
            };

            const response = await axios.post(`${API_URL}/adventures/${adventureId}/images`, formData, config);

            setPhotos([...photos, response.data]);

            setSnackbarConfig({
                message: "Photo uploaded successfully!",
                icon: 'notifications',
                title: 'Gallery',
                color: 'secondary'
            });

            setShow(true);
        } catch (error) {
            setSnackbarConfig({
                message: "Something went wrong uploading the photo",
                icon: 'cancel',
                title: 'Gallery',
                color: 'warning'
            });

            setShow(true);
        } finally {
            setSubmitting(false);
        }
    };

    return <Card id="gallery" sx={{overflow: "visible"}}>
        <MDBox p={3}>
            <Formik
                initialValues={{image: ''}}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({values, errors, touched, setFieldValue, setFieldTouched, submitForm, isSubmitting}) => (
                    <>
                    <Grid container>
                        <Grid item xs={12} sm={9}>
                            <MDTypography variant="h5">Gallery</MDTypography>
                            <MDTypography variant="body2">Add images of your adventure so customers know what to
                                expect.</MDTypography>
                        </Grid>
                        <Grid item xs={12} sm={3} display="flex" alignItems="center" justifyContent="flex-end">

                            <Form>
                                <Field name="image">
                                    {({field, form}) => (
                                        <div>
                                            <MDButton
                                                variant="outlined"
                                                color="secondary"
                                                type="button"
                                                onClick={handleImageButtonClick}
                                            >
                                                Add Photo
                                            </MDButton>
                                            <input
                                                ref={fileInputRef}
                                                type="file"
                                                accept="image/*"
                                                onChange={(event) => {
                                                    setFieldValue(field.name, event.currentTarget.files[0]);
                                                    setTimeout(() => {
                                                        submitForm();
                                                    }, 50);
                                                }}
                                                style={{display: 'none'}}
                                            />
                                        </div>
                                    )}
                                </Field>
                            </Form>
                        </Grid>
                    </Grid>
                        {errors.image ? (
                            <MDTypography variant="caption" color="error"
                                          fontWeight="regular">{errors.image}</MDTypography>
                        ) : null}
                    </>
                )}
            </Formik>
        </MDBox>
        <MDBox pb={3} px={3}>
            {uploadProgress > 0 && (
                <MDBox sx={{width: '100%'}} mb={2}>
                    <LinearProgressWithLabel color="secondary" value={uploadProgress}/>
                </MDBox>
            )}
            <MDBox sx={{
                backgroundColor: '#FAFAFA',
                padding: 2,
                borderStyle: 'solid',
                borderWidth: 1,
                borderColor: '#d6d6d6',
                borderRadius: 2
            }}>
                {
                    photos.length > 0 ?
                        <ImageList sx={{width: '100%'}} cols={4}>
                            {photos.map((image) => (
                                <ImageListItem key={image.id}>
                                    <img
                                        src={`${APP_URL}/storage/photos/${image.name}`}
                                        loading="lazy"
                                    />
                                    <ImageListItemBar
                                        title={image.name}
                                        actionIcon={
                                            <IconButton
                                                onClick={() => handleImageDelete(image.id)}
                                                sx={{color: 'rgba(255, 255, 255, 0.54)'}}
                                            >
                                                <Icon color="error">delete</Icon>
                                            </IconButton>
                                        }
                                    />
                                </ImageListItem>
                            ))}
                        </ImageList> :
                        <MDTypography variant="body2" align="center">You haven't added any photos</MDTypography>
                }

            </MDBox>
        </MDBox>
        <MDSnackbar
            color={snackbarConfig.color}
            icon={snackbarConfig.icon}
            title={snackbarConfig.title}
            content={snackbarConfig.message}
            dateTime=""
            autoHideDuration={3000}
            open={show}
            close={toggleSnackbar}
        />
    </Card>;
};


export default Gallery;
