import React, {useEffect, useState} from "react";
import axios from "axios";
import {API_URL} from "../../../config";
import {useSelector} from "react-redux";
import {useParams} from 'react-router-dom';

const useReservationDetails = () => {

    const { id } = useParams();

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    const [isLoading, setIsLoading] = useState(true);
    const [reservation, setReservation] = useState(null);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        setIsLoading(true);
        await loadReservation();
        setIsLoading(false)
    };

    const loadReservation = async () => {

        try {

            const response = await axios.get(`${API_URL}/reservations/${id}`);

            setReservation(response.data);

        } catch (error) {

            setSnackbarConfig({
                message: "Something went wrong loading the reservation's details",
                icon: 'cancel',
                title: 'Reservations',
                color: 'warning'
            });

            setShow(true);

        }

    };

    const updateStatus = async (status) => {
        setIsLoading(true);

        try {

            // Hacer request
            const response = await axios.put(
                `${API_URL}/reservations/${id}/update_status`,
                { status }
            );

            let newReservation = {...reservation};
            newReservation.status = status;

            setReservation(newReservation);

            setSnackbarConfig({
                message: 'Reservation updated successfully',
                icon: 'notifications',
                title: 'Reservation',
                color: 'secondary'
            });

            setShow(true);

        } catch(error){

            // Mostrar mensaje de error
            setSnackbarConfig({
                message: error.response.data.message,
                icon: 'cancel',
                title: 'Reservation',
                color: 'warning'
            });

            setShow(true);
        }

        setIsLoading(false);
    };

    return {
        id,
        reservation,
        isLoading,
        snackbarConfig,
        show,
        toggleSnackbar,
        updateStatus
    }
};

export default useReservationDetails;
