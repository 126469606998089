/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import FormField from "../../../../../../components/FormField";


function ChangePasswordForm({formData}) {

    const {formField, values, errors, touched} = formData;

    const {current_password, password, password_confirmation} = formField;
    const {
        current_password: currentPasswordV,
        password: passwordV,
        password_confirmation: passwordConfirmationV,
    } = values;

    return (
        <MDBox>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={current_password.type}
                        label={current_password.label}
                        name={current_password.name}
                        value={currentPasswordV}
                        error={errors.current_password && touched.current_password}
                        success={currentPasswordV.length > 0 && !errors.current_password}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={password.type}
                        label={password.label}
                        name={password.name}
                        value={passwordV}
                        placeholder={password.placeholder}
                        error={errors.password && touched.password}
                        success={passwordV.length > 0 && !errors.password}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={password_confirmation.type}
                        label={password_confirmation.label}
                        name={password_confirmation.name}
                        value={passwordConfirmationV}
                        placeholder={password_confirmation.placeholder}
                        error={errors.password_confirmation && touched.password_confirmation}
                        success={passwordConfirmationV.length > 0 && !errors.password_confirmation}
                    />
                </Grid>
            </Grid>
        </MDBox>
    );
}

export default ChangePasswordForm;
