import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "../TableCell";
import TableBody from "@mui/material/TableBody";
import Card from "@mui/material/Card";
import React from "react";
import Grid from "@mui/material/Grid";
import MDButton from "../../../../../components/MDButton";
import { useNavigate } from "react-router-dom";
import Icon from "@mui/material/Icon";
import {API_URL} from "../../../../../config";
import axios from "axios";

const Items = ({items, onRefresh}) => {

    let navigate = useNavigate();

    const handleItemDelete = async (id) => {
        await axios.delete(`${API_URL}/items/${id}`);
        await onRefresh();
    };

    const renderTableRows = () => {
        return items.map((item) => <TableRow>
            <TableCell key={item.id} padding={[1, 1, 1, 0.5]}>
                <MDBox lineHeight={1.4}>
                    <MDTypography display="block" variant="button"
                                  fontWeight="regular">
                        {item.name}
                    </MDTypography>
                    <MDTypography variant="caption" color="text"
                                  fontWeight="regular">
                        {item.description}
                    </MDTypography>
                </MDBox>
            </TableCell>
            <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                ${item.price}
            </TableCell>
            <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                <MDButton iconOnly variant="text" color="secondary" onClick={() => navigate(`/adventure/items/${item.id}/edit-item`)}>
                    <Icon>edit</Icon>
                </MDButton>
            </TableCell>
            <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                <MDButton iconOnly color="warning" variant="text" onClick={() => handleItemDelete(item.id)}>
                    <Icon>delete</Icon>
                </MDButton>
            </TableCell>
        </TableRow>)
    };

    return <Card id="items">
        <MDBox p={3} lineHeight={1}>
            <Grid container>
                <Grid item xs={12} sm={9}>
                    <MDTypography variant="h5">Items</MDTypography>
                    <MDTypography variant="body2">Add the different items you offer.</MDTypography>
                </Grid>
                <Grid item xs={12} sm={3} display="flex" alignItems="center" justifyContent="flex-end">
                    <MDButton onClick={() => navigate("/adventure/new-item")} variant="outlined" color="secondary">New Item</MDButton>
                </Grid>
            </Grid>
        </MDBox>
        <MDBox pb={3} px={3}>
            <MDBox minWidth="auto" sx={{overflow: "scroll"}}>
                {
                    items.length === 0 ?
                        <MDBox sx={{padding: 2, borderStyle: 'solid', borderWidth: 1, borderColor: '#d2d6da', textAlign: 'center'}}>
                            <MDTypography variant="body2">You have not added any items, add some so your viewers can purchase them.</MDTypography>
                        </MDBox> :
                        <Table sx={{minWidth: "36rem"}}>
                            <MDBox component="thead">
                                <TableRow>
                                    <TableCell width="100%" padding={[1.5, 3, 1.5, 0.5]}>
                                        Item
                                    </TableCell>
                                    <TableCell align="center" padding={[1.5, 6, 1.5, 6]}>
                                        Price
                                    </TableCell>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center"></TableCell>
                                </TableRow>
                            </MDBox>
                            <TableBody>
                                {renderTableRows()}
                            </TableBody>
                        </Table>
                }
            </MDBox>
        </MDBox>
    </Card>;
};

export default Items;
