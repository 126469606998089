/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { address, latitude, longitude, city_id },
} = checkout;

const validations = Yup.object().shape({
  [address.name]: Yup.string().required(address.errorMsg),
  [latitude.name]: Yup.string().required(latitude.errorMsg),
  [longitude.name]: Yup.string().required(longitude.errorMsg),
  [city_id.name]: Yup.number().required(city_id.errorMsg).positive(city_id.errorMsg),
});

export default validations;
