import MDTypography from "../../../../../components/MDTypography";
import List from "../../../../../components/Lists/List";
import Card from "@mui/material/Card";
import React from "react";
import MDBox from "../../../../../components/MDBox";
import Grid from "@mui/material/Grid";
import MDButton from "../../../../../components/MDButton";
import { useNavigate } from "react-router-dom";
import {useSelector} from "react-redux";


const Preys = ({items, adventureId}) => {

    const authState = useSelector((state) => state.authReducer);

    let navigate = useNavigate();

    return <Card id="preys" sx={{ overflow: "visible" }}>
        <MDBox p={3}>
            <Grid container>
                <Grid item xs={12} sm={10}>
                    <MDTypography variant="h5">Game</MDTypography>
                    <MDTypography variant="body2">Add the different types of game your guests can find on your adventure.</MDTypography>
                </Grid>
                <Grid item xs={12} sm={2} display="flex" alignItems="center" justifyContent="flex-end">
                    <MDButton onClick={() => authState.roleId === 1 ? navigate(`/adventure/${adventureId}/preys`) : navigate("/adventure/preys")} variant="outlined" color="secondary">Edit Preys</MDButton>
                </Grid>
            </Grid>

        </MDBox>
        <MDBox pb={3} px={3}>
            {
                items.length > 0 ?
                    <List
                        columns={2}
                        items={items}
                    /> :
                    <MDBox sx={{padding: 2, borderStyle: 'solid', borderWidth: 1, borderColor: '#d2d6da', textAlign: 'center'}}>
                        <MDTypography variant="body2">You have not added any target species, add some to improve your profile's performance.</MDTypography>
                    </MDBox>
            }
        </MDBox>
    </Card>;
};


export default Preys;
