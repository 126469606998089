/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "../../../../../../../components/FormField";


function LocationForm({formData, cities}) {

    const {formField, values, errors, touched} = formData;

    const {address, latitude, longitude, city_id} = formField;
    const {
        address: addressV,
        latitude: latitudeV,
        longitude: longitudeV,
        city_id: cityIdV,
    } = values;

    return (
        <MDBox>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={address.type}
                        label={address.label}
                        name={address.name}
                        value={addressV}
                        error={errors.address && touched.address}
                        success={addressV && addressV.length > 0 && !errors.address}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={city_id.type}
                        label={city_id.label}
                        name={city_id.name}
                        options={cities}
                        value={cityIdV}
                        error={errors.city_id && touched.city_id}
                        success={cityIdV && cityIdV.length > 0 && !errors.city_id}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={latitude.type}
                        label={latitude.label}
                        name={latitude.name}
                        value={latitudeV}
                        error={errors.latitude && touched.latitude}
                        success={latitudeV && latitudeV.length > 0 && !errors.latitude}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={longitude.type}
                        label={longitude.label}
                        name={longitude.name}
                        value={longitudeV}
                        error={errors.longitude && touched.longitude}
                        success={longitudeV && longitudeV.length > 0 && !errors.longitude}
                    />
                </Grid>
            </Grid>
            <MDTypography variant="caption">Find the coordinates using a tool like (https://www.latlong.net/)</MDTypography>
        </MDBox>
    );
}

export default LocationForm;
