import DashboardNavbar from "../../../components/Navbars/DashboardNavbar";
import MDBox from "../../../components/MDBox";
import Card from "@mui/material/Card";
import LinearProgress from "@mui/material/LinearProgress/LinearProgress";
import MUIDataTable from "mui-datatables";
import MDTypography from "../../../components/MDTypography";
import MDSnackbar from "../../../components/MDSnackbar";
import Footer from "../../../components/Footer";
import DashboardLayout from "../../../components/LayoutContainers/DashboardLayout";
import React from "react";
import useSelectPreys from "./useSelectPreys";
import Grid from "@mui/material/Grid";
import BaseLayout from "../components/BaseLayout";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import {FieldArray, Form, Formik} from "formik";
import EditPreyForm from "../edit-prey/components/EditPreyForm";
import MDButton from "../../../components/MDButton";
import FormField from "../../../components/FormField";
import {useSelector} from "react-redux";
import {useParams} from 'react-router-dom';

const SelectPreys = () => {

    const { id } = useParams();

    const {preys, adventurePreys, formId, isLoading, snackbarConfig, show, handleSubmit, toggleSnackbar} = useSelectPreys(id);

    return (
        <BaseLayout>
            <Grid container justifyContent="center">
                <Grid item xs={12} lg={8}>
                    <MDBox mb={3}>
                        <Card id="select-preys" sx={{overflow: "visible"}}>
                            {
                                isLoading ?
                                    <MDBox mt={2} mx={2} p={10} display="flex" justifyContent="center"
                                           alignItems="center">
                                        <CircularProgress color="secondary" style={{overflow: 'hidden'}}/>
                                    </MDBox> :
                                    <Formik
                                        initialValues={{preys: adventurePreys}}
                                        // validationSchema={currentValidation}
                                        onSubmit={handleSubmit}
                                    >
                                        {({values, errors, touched, isSubmitting}) => (
                                            <Form id={formId} autoComplete="off">
                                                <MDBox p={3}>
                                                    <MDTypography variant="h5">Edit Available Game</MDTypography>
                                                    <MDTypography variant="body2">Update the available game in your adventure.</MDTypography>
                                                </MDBox>
                                                <MDBox pb={3} px={3}>
                                                    <FieldArray
                                                        name="preys"
                                                        render={arrayHelpers => (
                                                            <Grid container spacing={3}>
                                                                {preys.map(prey => (
                                                                    <Grid item xs={12} sm={4}>
                                                                        <FormField
                                                                            type="checkbox"
                                                                            label={prey.name}
                                                                            name="preys"
                                                                            value={prey.id}
                                                                            checked={values.preys.includes(prey.id)}
                                                                            onChange={e => {
                                                                                if (e.target.checked) {
                                                                                    arrayHelpers.push(prey.id);
                                                                                } else {
                                                                                    const idx = values.preys.indexOf(prey.id);
                                                                                    arrayHelpers.remove(idx);
                                                                                }
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                ))}
                                                            </Grid>
                                                        )}
                                                    />
                                                    <MDBox mt={2} width="100%" display="flex"
                                                           justifyContent="flex-end">
                                                        <MDBox>
                                                            <MDButton
                                                                disabled={isSubmitting}
                                                                variant="gradient"
                                                                color="secondary"
                                                                type="submit"
                                                            >
                                                                {isSubmitting &&
                                                                <CircularProgress size={18} color="white"
                                                                                  sx={{marginRight: 1}}/>}
                                                                Update
                                                            </MDButton>
                                                        </MDBox>
                                                    </MDBox>
                                                </MDBox>

                                            </Form>
                                        )}
                                    </Formik>
                            }
                        </Card>
                    </MDBox>
                </Grid>
            </Grid>
            <MDSnackbar
                color={snackbarConfig.color}
                icon={snackbarConfig.icon}
                title={snackbarConfig.title}
                content={snackbarConfig.message}
                dateTime=""
                autoHideDuration={3000}
                open={show}
                close={toggleSnackbar}
            />
        </BaseLayout>
    )
};

export default SelectPreys;
