import React, {useEffect, useState} from "react";
import axios from "axios";
import {API_URL} from "../../../../../config";
import ReviewCard from "../../../../../components/Cards/ReviewCard/ReviewCard";
import moment from "moment";

const useReviews = ({id}) => {

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    const [isLoading, setIsLoading] = useState(true);

    const [reviews, setReviews] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(null);

    useEffect(() => {
        const init = async () => {
            await loadReviews();
        };

        init();
    }, [currentPage]);

    const loadReviews = async () => {
        setIsLoading(true);

        try {

            const response = await axios.get(`${API_URL}/reviews`, {
                params: {
                    adventure_id: id,
                    sort: 'created_at',
                    direction: 'desc',
                    page: currentPage,
                    per_page: 10
                }
            });

            console.log(response.data);

            setLastPage(response.data.last_page);
            setReviews(response.data.data);

        } catch (error) {

            setSnackbarConfig({
                message: "Something went wrong loading the adventure's reviews",
                icon: 'cancel',
                title: 'Adventures',
                color: 'warning'
            });

            setShow(true);

        }

        // Esconder indicador de carga
        setIsLoading(false);
    };

    const onNextPageClick = () => {
        if(currentPage < lastPage)
            setCurrentPage(currentPage + 1);
    };

    const onPreviousPageClick = () => {
        if(currentPage !== 1)
            setCurrentPage(currentPage - 1);
    };

    const renderReviews = () => {
        return reviews.map(review => (<ReviewCard key={review.id} title={review.title} content={review.content} score={review.score} user={review.user.name} createdAt={moment(review.created_at).format('MMM DD, YYYY')} />))
    };

    return {reviews, isLoading, snackbarConfig, show, renderReviews, onNextPageClick, onPreviousPageClick, toggleSnackbar}
};

export default useReviews;
