import {useDispatch, useSelector} from "react-redux";
import form from "./schemas/form";
import validations from "./schemas/validations";
import {useEffect, useState} from "react";
import axios from "axios";
import {API_URL} from "../../../../../config";

const useLocation = (address, latitude, longitude, cityId, adventureId) => {

    const authState = useSelector((state) => state.authReducer);

    const dispatch = useDispatch();

    const {formId, formField} = form;
    const currentValidation = validations;

    const [values, setValues] = useState({address, latitude, longitude, city_id: cityId});

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    const handleSubmit = async (values, actions) => {

        try {

            // Enviar datos a API
            const response = await axios.put(
                `${API_URL}/adventures/${adventureId}`,
                {...values}
            );

            setSnackbarConfig({
                message: 'Adventure address updated successfully',
                icon: 'notifications',
                title: 'Adventure Address',
                color: 'secondary'
            });

            setShow(true);

        } catch (error) {

            setSnackbarConfig({
                message: error.response.data.message,
                icon: 'cancel',
                title: 'Adventure Address',
                color: 'warning'
            });

            setShow(true);

        }

        actions.setSubmitting(false);

    };

    return {
        formId,
        formField,
        currentValidation,
        values,
        snackbarConfig,
        show,
        handleSubmit,
        toggleSnackbar
    };
};

export default useLocation;
