import DashboardLayout from "../../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../components/Navbars/DashboardNavbar";
import MDBox from "../../components/MDBox";
import LinearProgress from "@mui/material/LinearProgress/LinearProgress";
import React, {useEffect, useState} from "react";
import Card from "@mui/material/Card";
import MessageField from "./components/MessageField/MessageField";
import {API_URL, PUSHER_ID} from "../../config";
import axios from "axios";
import {useParams} from 'react-router-dom';
import Messages from "./components/Messages/Messages";
import Grid from "@mui/material/Grid";
import MDTypography from "../../components/MDTypography";
import {useSelector} from "react-redux";
import Pusher from "pusher-js";
import MDSnackbar from "../../components/MDSnackbar";

const Chat = () => {

    const {id} = useParams();

    const audio = new Audio("/notification.mp3")

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    const authState = useSelector((state) => state.authReducer);

    const [isLoading, setIsLoading] = useState(true);

    const [messages, setMessages] = useState([]);

    useEffect(() => {

        const pusher = new Pusher(PUSHER_ID, {
            cluster: 'us2',
            authEndpoint: `${API_URL}/broadcasting/auth`,
            authorizer: (channel, options) => {
                return {
                    authorize: (socketId, callback) => {
                        axios.post(`${API_URL}/broadcasting/auth`, {
                            socket_id: socketId,
                            channel_name: channel.name
                        })
                            .then(response => {
                                callback(false, response.data);
                            })
                            .catch(error => {
                                callback(true, error);
                            });
                    }
                };
            },
        });

        const channel = pusher.subscribe(`private-reservations.${id}`);
        channel.bind('new-message', function(data) {

            setMessages([...messages, data.message]);

            audio.play();

        });

        return () => {
            pusher.disconnect();
        }

    }, [messages]);

    useEffect(() => {

        init();

    }, []);

    const init = async () => {
        setIsLoading(true);
        await loadMessages();
        setIsLoading(false);
    };

    const loadMessages = async () => {

        try {

            const response = await axios.get(`${API_URL}/messages/${id}`);

            setMessages(response.data);

        } catch (error) {

            setSnackbarConfig({
                message: "Something went wrong loading the messages",
                icon: 'cancel',
                title: 'Chat',
                color: 'warning'
            });

            setShow(true);
        }
    };

    const handleSend = async (message) => {

        try {
            let data = {
                message,
                user_id: authState.userId,
                reservation_id: id
            };

            const response = await axios.post(`${API_URL}/messages`, data);

            setMessages([...messages, response.data]);

            // Handle success
        } catch (error) {
            // Handle error
            setSnackbarConfig({
                message: "Something went wrong sending the message",
                icon: 'cancel',
                title: 'Chat',
                color: 'warning'
            });

            setShow(true);
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox pt={2} pb={3}>
                <Grid container justifyContent="center">
                    <Grid item xs={12} lg={8}>
                        <MDBox mb={3}>
                            {
                                isLoading ?
                                    <MDBox mt={2} mx={2}>
                                        <LinearProgress color="secondary" style={{overflow: 'hidden'}}/>
                                    </MDBox> :
                                    <Card>
                                        <MDBox mx={3} mt={2}>
                                            <MDTypography variant="h4">Customer Chat</MDTypography>
                                            <MDTypography variant="body2">Chat with your customer to learn more about
                                                their experience needs.</MDTypography>
                                        </MDBox>
                                        <Messages sx={{maxHeight: '50vh', overflow: 'scroll'}} messages={messages}/>
                                        <MDBox p={1}>
                                            <MessageField handleSend={handleSend}/>
                                        </MDBox>
                                    </Card>
                            }
                        </MDBox>
                    </Grid>
                </Grid>
            </MDBox>
            <MDSnackbar
                color={snackbarConfig.color}
                icon={snackbarConfig.icon}
                title={snackbarConfig.title}
                content={snackbarConfig.message}
                dateTime=""
                autoHideDuration={3000}
                open={show}
                close={toggleSnackbar}
            />
        </DashboardLayout>
    );
};

export default Chat;
