/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "edit-prey-form",
  formField: {
    name: {
      name: "name",
      label: "Name",
      type: "text",
      errorMsg: "Game name is required.",
    },
    description: {
      name: "description",
      label: "Description",
      type: "text",
      errorMsg: "Game description is required.",
    },
    type_id: {
      name: "type_id",
      label: "Type",
      type: "select",
      errorMsg: "Type is required.",
      invalidMsg: 'Must choose a valid type'
    }
  },
};

export default form;
