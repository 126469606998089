import React from 'react';
import TextField from "@mui/material/TextField";
import MDButton from "../../../../components/MDButton";
import Icon from "@mui/material/Icon";
import {API_URL} from "../../../../config";
import MDBox from "../../../../components/MDBox";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";

const MessageField = ({handleSend}) => {
    const [message, setMessage] = React.useState('');
    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const handleChange = (event) => {
        setMessage(event.target.value);
    };

    const onSendPress = async () => {
        setIsSubmitting(true);
        await handleSend(message);
        setIsSubmitting(false);
        setMessage('');
    };

    return (
        <MDBox display="flex" flexDirection="row" alignItems="center">
            <TextField
                fullWidth
                disabled={isSubmitting}
                label="Message"
                value={message}
                onChange={handleChange}
            />
            <MDButton variant="contained" color="secondary" disabled={isSubmitting} onClick={onSendPress}>
                {
                    isSubmitting ?
                        <CircularProgress style={{overflow: 'hidden'}}/> :
                        <Icon style={{marginRight: 8}}>send</Icon>
                }
                Send
            </MDButton>
        </MDBox>
    );
};

export default MessageField;
