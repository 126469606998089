import Grid from "@mui/material/Grid";
import Sidenav from "../Sidenav";
import Header from "../Header";
import BasicInfo from "../BasicInfo";
import Preys from "../Preys/Preys";
import Amenities from "../Amenities/Amenities";
import React from "react";
import MDBox from "../../../../../components/MDBox";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import Location from "../Location/Location";
import MDSnackbar from "../../../../../components/MDSnackbar";
import useProfile from "./useProfile";
import {useSelector} from "react-redux";
import Items from "../Items/Items";
import Gallery from "../Gallery/Gallery";
import Card from "@mui/material/Card";
import MDTypography from "../../../../../components/MDTypography";
import MDButton from "../../../../../components/MDButton";

const Profile = ({id}) => {

    const authState = useSelector((state) => state.authReducer);

    const {profile, cities, isLoading, snackbarConfig, show, init, toggleSnackbar, formatAmenitiesData, formatPreysData, handleReviewClick, handlePublishClick} = useProfile(id);

    return <Grid container spacing={3}>
        {
            isLoading ?
                <MDBox mt={2} mx={2} p={10} display="flex" justifyContent="center"
                       alignItems="center">
                    <CircularProgress color="secondary" style={{overflow: 'hidden'}}/>
                </MDBox> :
                <>
                    {
                        authState.roleId === 1 && !profile.is_approved &&
                            <Grid item xs={12} lg={12}>
                                <Card sx={{padding: 2, borderBottomStyle: 'solid', borderBottomColor: 'green', borderBottomWidth: 4}}>
                                    <MDBox sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                                        <MDBox sx={{flex: 1}}>
                                            <MDTypography variant="h5">Profile is pending review</MDTypography>
                                            <MDTypography variant="body2">Make sure to publish the profile when it is ready.</MDTypography>
                                        </MDBox>
                                        <MDButton onClick={handlePublishClick} variant="contained" color="secondary">Publish</MDButton>
                                    </MDBox>
                                </Card>
                            </Grid>
                    }
                    {
                        authState.roleId === 2 &&
                        (profile.is_approved ?
                            <Grid item xs={12} lg={12}>
                                <Card sx={{padding: 2, borderBottomStyle: 'solid', borderBottomColor: 'green', borderBottomWidth: 4}}>
                                    <MDBox sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                                        <MDBox sx={{flex: 1}}>
                                            <MDTypography variant="h5">Your profile is published!</MDTypography>
                                            <MDTypography variant="body2">You're ready to start receiving bookings through Teku.</MDTypography>
                                        </MDBox>
                                    </MDBox>
                                </Card>
                            </Grid> :
                            profile.is_complete ?
                                <Grid item xs={12} lg={12}>
                                    <Card sx={{padding: 2, borderBottomStyle: 'solid', borderBottomColor: 'green', borderBottomWidth: 4}}>
                                        <MDBox sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                                            <MDBox sx={{flex: 1}}>
                                                <MDTypography variant="h5">Your profile seems ready for review!</MDTypography>
                                                <MDTypography variant="body2">If you're ready to get your listing published, send a review request by clicking the following button.</MDTypography>
                                            </MDBox>
                                            <MDButton onClick={handleReviewClick} variant="contained" color="secondary">Review my profile</MDButton>
                                        </MDBox>
                                    </Card>
                                </Grid> :
                                <Grid item xs={12} lg={12}>
                                    <Card sx={{padding: 2, borderBottomStyle: 'solid', borderBottomColor: 'red', borderBottomWidth: 4}}>
                                        <MDTypography variant="h5">Your profile is missing key information!</MDTypography>
                                        <MDTypography variant="body2">To publish your listing, be sure to fill out your profile completely.</MDTypography>
                                    </Card>
                                </Grid>)
                    }
                    <Grid item xs={12} lg={3}>
                        <Sidenav/>
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <MDBox mb={3}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Header name={profile.name} score={profile.rating} />
                                </Grid>
                                <Grid item xs={12}>
                                    <BasicInfo adventureId={id} editable={authState.roleId === 2} name={profile.name} shortDescription={profile.short_description} description={profile.description}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Gallery adventureId={id} images={profile.images}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Preys adventureId={id} items={formatPreysData()}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Amenities adventureId={id} items={formatAmenitiesData()} onRefresh={() => init()}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Location adventureId={id} address={profile.address} latitude={profile.latitude} longitude={profile.longitude} cityId={profile.city_id} cities={cities} />
                                </Grid>
                                <Grid item xs={12}>
                                    {/*<Images />*/}
                                </Grid>
                                <Grid item xs={12}>
                                    <Items items={profile.items.filter(item => item.is_active == true)} onRefresh={() => init()}/>
                                </Grid>
                            </Grid>
                        </MDBox>
                    </Grid>
                    <MDSnackbar
                        color={snackbarConfig.color}
                        icon={snackbarConfig.icon}
                        title={snackbarConfig.title}
                        content={snackbarConfig.message}
                        dateTime=""
                        autoHideDuration={3000}
                        open={show}
                        close={toggleSnackbar}
                    />
                </>
        }
    </Grid>;
};

export default Profile;
