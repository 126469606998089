/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "location-form",
  formField: {
    address: {
      name: "address",
      label: "Address",
      type: "text",
      errorMsg: "Address name is required."
    },
    latitude: {
      name: "latitude",
      label: "Latitude",
      type: "text",
      errorMsg: "Latitude is required."
    },
    longitude: {
      name: "longitude",
      label: "Longitude",
      type: "text",
      errorMsg: "Longitude is required."
    },
    city_id: {
      name: "city_id",
      label: "City",
      type: "select",
      errorMsg: "City is required.",
      invalidMsg: "City is required.",
    }
  },
};

export default form;
