/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import FormField from "../../../../../../../components/FormField";


function BasicInfoForm({formData, cities}) {

    const {formField, values, errors, touched} = formData;

    const {name, short_description, description} = formField;
    const {
        name: nameV,
        short_description: shortDescriptionV,
        description: descriptionV
    } = values;

    return (
        <MDBox>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={name.type}
                        label={name.label}
                        name={name.name}
                        value={nameV}
                        error={errors.name && touched.name}
                        success={nameV && nameV.length > 0 && !errors.name}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={short_description.type}
                        label={short_description.label}
                        name={short_description.name}
                        value={shortDescriptionV}
                        multiline={true}
                        rows={2}
                        error={errors.short_description && touched.short_description}
                        success={shortDescriptionV && shortDescriptionV.length > 0 && !errors.short_description}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={description.type}
                        label={description.label}
                        name={description.name}
                        value={descriptionV}
                        multiline={true}
                        rows={4}
                        error={errors.description && touched.description}
                        success={descriptionV && descriptionV.length > 0 && !errors.description}
                    />
                </Grid>
            </Grid>
        </MDBox>
    );
}

export default BasicInfoForm;
