import React from "react";
import Card from "@mui/material/Card";
import MUIDataTable from "mui-datatables";
import LinearProgress from "@mui/material/LinearProgress/LinearProgress";
import MDSnackbar from "../../../components/MDSnackbar";
import Footer from "../../../components/Footer";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import DashboardNavbar from "../../../components/Navbars/DashboardNavbar";
import DashboardLayout from "../../../components/LayoutContainers/DashboardLayout";
import useUsers from "./useUsers";

const Users = ({ roleId }) => {

    const {isLoading, options, columns, data, show, snackbarConfig, renderTitle, renderIntro, toggleSnackbar} = useUsers(roleId);

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox pt={2} pb={3}>
                <MDBox mb={3}>
                    <Card>
                        {
                            isLoading ?
                                <MDBox mt={2} mx={2}>
                                    <LinearProgress color="secondary" style={{overflow: 'hidden'}}/>
                                </MDBox> :
                                null
                        }
                        <MUIDataTable
                            title={
                                <MDBox p={3} lineHeight={1}>
                                    <MDTypography variant="h5" fontWeight="medium">
                                        {renderTitle()}
                                    </MDTypography>
                                    <MDTypography variant="button" color="text">
                                        {renderIntro()}
                                    </MDTypography>
                                </MDBox>
                            }
                            data={data}
                            columns={columns}
                            options={options}
                        />
                    </Card>
                </MDBox>
            </MDBox>
            <MDSnackbar
                color={snackbarConfig.color}
                icon={snackbarConfig.icon}
                title={snackbarConfig.title}
                content={snackbarConfig.message}
                dateTime=""
                autoHideDuration={3000}
                open={show}
                close={toggleSnackbar}
            />
            <Footer/>
        </DashboardLayout>
    );
};

export default Users;
