/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React, {useEffect, useMemo, useState} from "react";
// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// Material Dashboard 2 PRO React examples
import Header from "./components/Header";
import moment from "moment";
import axios from "axios";
import {useSelector} from "react-redux";
import {API_URL} from "../../../config";
import MDSnackbar from "../../../components/MDSnackbar";
import DashboardLayout from "../../../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../components/Navbars/DashboardNavbar";
import Footer from "../../../components/Footer";
import Calendar from "../../../components/Calendar";
import AppointmentDetails from "./components/AppointmentDetails";
import { useNavigate } from "react-router-dom";


// Calendar application components

// Data

const ReservationsCalendar = () => {

    const authState = useSelector((state) => state.authReducer);

    let navigate = useNavigate();

    const [isCalendarLoading, setIsCalendarLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [calendarData, setCalendarData] = useState([]);

    const [selectedReservation, setSelectedReservation] = useState(null);

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    const updateReservation = async (url, data) => {

        const response = await axios.put(url, data);

        return {
            data: response.data
        };

    };

    const loadCalendarData = async (url, from, to) => {

        const options = {
            params: {
                from,
                to,
                adventure_id: authState.adventureId
            }
        };

        const response = await axios.get(url, options);

        return {
            data: response.data
        };

    };

    const getEventClass = (status) => {
        switch (status) {
            case 'pending':
                return 'primary';
            case 'rejected':
                return 'warning';
            case 'accepted':
                return 'secondary';
        }
    };

    const formatCalendarData = (calendarData) => {
        return calendarData.map(event => ({
            id: event.id,
            title: `Trip with ${event.user.name}`,
            start: event.due_at,
            email: event.user.email,
            phone: event.user.phone,
            status: event.status,
            className: getEventClass(event.status)
        }))
    };

    const handleDateChange = async (dateInfo) => {

        const startMoment = moment(dateInfo.start);
        const endMoment = moment(dateInfo.end);

        setIsCalendarLoading(true);

        const response = await loadCalendarData(`${API_URL}/reservations`, startMoment.format('YYYY-MM-DD'), endMoment.format('YYYY-MM-DD'));

        setIsCalendarLoading(false);

        setCalendarData(formatCalendarData(response.data));

    };

    const handleEventClick = (eventInfo) => {

        let event = calendarData.find(reservation => reservation.id == eventInfo.event.id);

        if (event)
            setSelectedReservation(event);
    };

    const handleReject = async (id) => {
        setIsSubmitting(true);
        setIsCalendarLoading(true);

        const response = await updateReservation(`${API_URL}/reservations/${id}/update_status`, {status: 'rejected'});

        let newCalendarData = [...calendarData];

        const appointmentIndex = newCalendarData.findIndex((reservation) => reservation.id === id);

        newCalendarData[appointmentIndex].status = 'rejected';
        newCalendarData[appointmentIndex].className = 'warning';

        setCalendarData(newCalendarData);

        setIsSubmitting(false);
        setIsCalendarLoading(false);

        // Notification state
        setSnackbarConfig({
            message: 'The reservation has been rejected successfully.',
            icon: 'notifications',
            title: 'Reservations',
            color: 'secondary'
        });

        setShow(true);
    };


    const handleAccept = async (id) => {

        setIsSubmitting(true);
        setIsCalendarLoading(true);

        await updateReservation(`${API_URL}/reservations/${id}/update_status`, {status: 'accepted'});

        let newCalendarData = [...calendarData];

        const appointmentIndex = newCalendarData.findIndex((appointment) => appointment.id === id);

        newCalendarData[appointmentIndex].status = 'accepted';
        newCalendarData[appointmentIndex].className = 'secondary';

        setCalendarData(newCalendarData);

        setIsSubmitting(false);
        setIsCalendarLoading(false);

        // Notification state
        setSnackbarConfig({
            message: 'The reservation has been updated successfully.',
            icon: 'notifications',
            title: 'Reservations',
            color: 'secondary'
        });

        setShow(true);
    };

    return (
        <DashboardLayout>
            <DashboardNavbar/>

            <MDBox pt={2}>
                <Grid container spacing={3}>
                    <Grid item xs={12} xl={9}>
                        {useMemo(
                            () => (
                                <Calendar
                                    isLoading={isCalendarLoading}
                                    initialView="dayGridMonth"
                                    initialDate={moment().format('YYYY-MM-DD')}
                                    datesSet={handleDateChange}
                                    events={calendarData}
                                    eventClick={handleEventClick}
                                    selectable
                                    editable
                                />
                            ),
                            [calendarData, isCalendarLoading]
                        )}
                    </Grid>

                        <Grid item xs={12} xl={3}>
                            {
                                selectedReservation !== null &&
                                <MDBox mb={3}>
                                    <AppointmentDetails data={selectedReservation}
                                                        isSubmitting={isSubmitting}
                                                        onDetailsClick={() => navigate(`/reservations/${selectedReservation.id}`)}
                                                        onRejectClick={() => handleReject(selectedReservation.id)}
                                                        onAcceptClick={() => handleAccept(selectedReservation.id)}
                                    />
                                </MDBox>
                            }
                            {
                                authState.roleId !== 1 &&
                                <MDBox mb={3}>
                                    {/*<NextEvents data={upcomingData}/>*/}
                                </MDBox>
                            }
                        </Grid>

                </Grid>
            </MDBox>
            <Footer/>
            <MDSnackbar
                color={snackbarConfig.color}
                icon={snackbarConfig.icon}
                title={snackbarConfig.title}
                content={snackbarConfig.message}
                dateTime=""
                autoHideDuration={3000}
                open={show}
                close={toggleSnackbar}
            />
        </DashboardLayout>
    );
}

export default ReservationsCalendar;
