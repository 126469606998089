/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "new-city-form",
  formField: {
    name: {
      name: "name",
      label: "Name",
      type: "text",
      errorMsg: "City name is required.",
    },
    latitude: {
      name: "latitude",
      label: "Center Latitude",
      type: "text",
      errorMsg: "Latitude is required.",
    },
    longitude: {
      name: "longitude",
      label: "Longitude",
      type: "text",
      errorMsg: "Longitude is required.",
    },
    state_id: {
      name: "state_id",
      label: "State",
      type: "select",
      errorMsg: "State is required.",
    }
  },
};

export default form;
