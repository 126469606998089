
export const ENVIRONMENT = 'PRODUCTION';

export const MAPS_API_KEY = "AIzaSyBO9lgtvfo8Wg9Czx6diTbWQwog-LPQOS8";

const getAppUrl = () => {
    if(ENVIRONMENT === 'LOCAL')
        return ('http://localhost:8000');

    if(ENVIRONMENT === 'STAGING')
        return 'https://api.teku.astwarehosting.com';

    if(ENVIRONMENT === 'PRODUCTION')
        return 'https://api.tekuoutdoors.com';
};

export const APP_URL = getAppUrl();
export const API_URL = APP_URL + '/api';

export const PUSHER_ID = 'a2774cf397b9c638463a';
