/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "new-user-form",
  formField: {
    name: {
      name: "name",
      label: "Name",
      type: "text",
      errorMsg: "Name is required.",
    },
    email: {
      name: "email",
      label: "Email",
      type: "email",
      errorMsg: "Email is required.",
      invalidMsg: "The email you entered is invalid.",
    },
    password: {
      name: "password",
      label: "Password",
      type: "password",
      errorMsg: "Password is required.",
      invalidMsg: "Password must have at least 6 characters",
    },
    password_confirmation: {
      name: "password_confirmation",
      label: "Confirm Password",
      type: "password",
      errorMsg: "Password confirmation is required.",
      invalidMsg: "Passwords did not match.",
    },
  },
};

export default form;
