import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import {Status, Wrapper} from "@googlemaps/react-wrapper";
import {MAPS_API_KEY} from "../../../../../config";
import Map from "../../../../../components/Map/Map";
import Marker from "../../../../../components/Map/Marker";
import Card from "@mui/material/Card";
import React from "react";
import LocationForm from "./components/LocationForm";
import MDButton from "../../../../../components/MDButton";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import {Form, Formik} from "formik";
import MDSnackbar from "../../../../../components/MDSnackbar";
import useLocation from "./useLocation";

const Location = ({address, latitude, longitude, cityId, cities, adventureId}) => {

    const {formId, formField, values, currentValidation, snackbarConfig, show, toggleSnackbar, handleSubmit} = useLocation(address || '', latitude || '', longitude || '', cityId || -1, adventureId);

    const render = (status) => {
        if (status === Status.LOADING) return <h3>{status} ..</h3>;
        if (status === Status.FAILURE) return <h3>{status} ...</h3>;
        return null;
    };

    return <>
        <Card id="location">
            <Formik
                initialValues={values}
                validationSchema={currentValidation}
                onSubmit={handleSubmit}
            >
                {({values, errors, touched, isSubmitting}) => (
                    <Form id={formId} autoComplete="off">
                        <MDBox p={3} lineHeight={1}>
                            <MDTypography variant="h5">Location</MDTypography>
                            <MDTypography variant="body2">Enter your adventure's address details to be able to be easily
                                found by customers.</MDTypography>
                        </MDBox>
                        <MDBox pb={3} px={3}>
                            {
                                latitude !== null && longitude !== null &&
                                <MDBox mb={5}>
                                    <Wrapper apiKey={MAPS_API_KEY} render={render}>
                                        <Map zoom={15} center={{lat: parseFloat(latitude), lng: parseFloat(longitude)}}>
                                            <Marker position={{lat: parseFloat(latitude), lng: parseFloat(longitude)}}/>
                                        </Map>
                                    </Wrapper>
                                </MDBox>
                            }
                            <LocationForm formData={{values, touched, formField, errors}} cities={cities}/>
                            <MDBox mt={2} width="100%" display="flex"
                                   justifyContent="flex-end">
                                <MDBox>
                                    <MDButton
                                        disabled={isSubmitting}
                                        variant="gradient"
                                        color="secondary"
                                        size="small"
                                        type="submit"
                                    >
                                        {isSubmitting &&
                                        <CircularProgress size={18} color="white" sx={{marginRight: 1}}/>}
                                        update
                                    </MDButton>
                                </MDBox>
                            </MDBox>
                        </MDBox>
                    </Form>
                )}
            </Formik>
        </Card>
        <MDSnackbar
            color={snackbarConfig.color}
            icon={snackbarConfig.icon}
            title={snackbarConfig.title}
            content={snackbarConfig.message}
            dateTime=""
            autoHideDuration={3000}
            open={show}
            close={toggleSnackbar}
        />
    </>
};

export default Location;
