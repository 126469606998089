/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// Material Dashboard 2 PRO React components
import MDInput from "components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";

function MDAutocomplete({input, field, form, label, options, ...rest}) {

    return (
        <Autocomplete
            {...field}
            fullWidth
            options={options}
            getOptionLabel={(option) => {
                if(typeof option === 'number') {
                    return options.find(opt => opt.id === option).label
                } else if(typeof option === 'object') {
                    return option.label
                }
            }}
            isOptionEqualToValue={(option, value) => option.id === value}
            onChange={(event, value) => {
                form.setFieldValue(field.name, value.id)
            }}
            renderInput={(params) => <MDInput {...params} variant="outlined" label={label} />}
        />
    );
}

export default MDAutocomplete;
