import MDTypography from "../../../../../components/MDTypography";
import List from "../../../../../components/Lists/List";
import Card from "@mui/material/Card";
import React from "react";
import MDBox from "../../../../../components/MDBox";
import Grid from "@mui/material/Grid";
import MDButton from "../../../../../components/MDButton";
import { useNavigate } from "react-router-dom";
import {API_URL} from "../../../../../config";
import axios from "axios";
import {useSelector} from "react-redux";

const Amenities = ({items, onRefresh, adventureId}) => {

    const authState = useSelector((state) => state.authReducer);

    let navigate = useNavigate();

    const handleDeleteClick = async (id) => {
        await axios.delete(`${API_URL}/amenities/${id}`);
        await onRefresh();
    };

    return <Card id="amenities" sx={{ overflow: "visible" }}>
        <MDBox p={3}>
            <Grid container>
                <Grid item xs={12} sm={9}>
                    <MDTypography variant="h5">Amenities</MDTypography>
                    <MDTypography variant="body2">Add the different amenities or features that make your adventure unique.</MDTypography>
                </Grid>
                <Grid item xs={12} sm={3} display="flex" alignItems="center" justifyContent="flex-end">
                    <MDButton onClick={() => authState.roleId === 1 ? navigate(`/adventure/${adventureId}/new-amenity`) : navigate("/adventure/new-amenity")} variant="outlined" color="secondary">New Amenity</MDButton>
                </Grid>
            </Grid>

        </MDBox>
        <MDBox pb={3} px={3}>
            {
                items.length > 0 ?
                    <List
                        columns={2}
                        items={items}
                        action={handleDeleteClick}
                    /> :
                    <MDBox sx={{padding: 2, borderStyle: 'solid', borderWidth: 1, borderColor: '#d2d6da', textAlign: 'center'}}>
                        <MDTypography variant="body2">You have not added any amenities, add some to improve your profile's performance.</MDTypography>
                    </MDBox>
            }
        </MDBox>
    </Card>;
};


export default Amenities;
