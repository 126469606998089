import React from "react";
import Card from "@mui/material/Card";
import MUIDataTable from "mui-datatables";
import LinearProgress from "@mui/material/LinearProgress/LinearProgress";
import MDSnackbar from "../../../components/MDSnackbar";
import Footer from "../../../components/Footer";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import DashboardNavbar from "../../../components/Navbars/DashboardNavbar";
import DashboardLayout from "../../../components/LayoutContainers/DashboardLayout";
import useReservationDetails from "./useReservationDetails";
import Grid from "@mui/material/Grid";
import TableRow from "@mui/material/TableRow";
import TableCell from "../../adventures/details/components/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import MDButton from "../../../components/MDButton";
import Icon from "@mui/material/Icon";
import moment from "moment";
import { useNavigate } from "react-router-dom";


const IconBox = ({icon, label, value}) => {
    return <MDBox display="flex" flexDirection="row" alignItems="center" sx={{
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#d2d6da',
        borderRadius: 2,
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: 2,
        paddingLeft: 2
    }}>
        <Icon color="secondary">{icon}</Icon>
        <MDBox ml={2}>
            <MDTypography variant="button" fontWeight="bold">{label}</MDTypography>
            <MDTypography variant="subtitle2">{value}</MDTypography>
        </MDBox>
    </MDBox>;
};

const ReservationDetails = () => {

    const {id, reservation, isLoading, show, snackbarConfig, toggleSnackbar, updateStatus} = useReservationDetails();

    let navigate = useNavigate();

    const renderTableRows = () => {
        return reservation.items.map((item) => <TableRow>
            <TableCell key={item.id} padding={[1, 1, 1, 0.5]}>
                <MDBox lineHeight={1.4}>
                    <MDTypography display="block" variant="button"
                                  fontWeight="regular">
                        {item.name}
                    </MDTypography>
                </MDBox>
            </TableCell>
            <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                ${item.pivot.price}
            </TableCell>
        </TableRow>)
    };

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox pt={2} pb={3}>
                <MDBox mb={3}>
                    {
                        isLoading ?
                            <MDBox mt={2} mx={2}>
                                <LinearProgress color="secondary" style={{overflow: 'hidden'}}/>
                            </MDBox> :
                            <Grid container spacing={3}>
                                <Grid item sm={12} md={8}>
                                    <Card>
                                        <MDBox sx={{padding: 3}}>
                                            <MDTypography variant="h5">Reservation Details</MDTypography>
                                            <MDTypography variant="body2">View the customer's reservation details.</MDTypography>
                                            <MDBox my={2} display="flex" flexDirection="row" alignItems="center"
                                                   justifyContent="flex-start">
                                                <MDBox mr={2}>
                                                    <IconBox icon="calendar_today" label="Reservation Date"
                                                             value={moment(reservation.due_at).format('YYYY-MM-DD')}/>
                                                </MDBox>
                                                <MDBox mr={2}>
                                                    <IconBox icon="access_time" label="Reservation Time"
                                                             value={moment(reservation.due_at).format('HH:mm')}/>
                                                </MDBox>
                                                <MDBox mr={2}>
                                                    <IconBox icon="autorenew" label="Status"
                                                             value={reservation.status.toUpperCase()}/>
                                                </MDBox>
                                            </MDBox>
                                            <MDTypography variant="h6">Reservation Items</MDTypography>
                                            <MDBox mt={2}>
                                                <Table sx={{minWidth: "36rem"}}>
                                                    <MDBox component="thead">
                                                        <TableRow>
                                                            <TableCell width="100%" padding={[1.5, 3, 1.5, 0.5]}>
                                                                Item
                                                            </TableCell>
                                                            <TableCell align="center" padding={[1.5, 6, 1.5, 6]}>
                                                                Price
                                                            </TableCell>
                                                        </TableRow>
                                                    </MDBox>
                                                    <TableBody>
                                                        {renderTableRows()}
                                                        <TableRow>
                                                            <TableCell width="100%" padding={[1.5, 3, 1.5, 0.5]}>
                                                                Total
                                                            </TableCell>
                                                            <TableCell align="center" padding={[1.5, 6, 1.5, 6]}>
                                                                ${reservation.total}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell width="100%" padding={[1.5, 3, 1.5, 0.5]}>
                                                                Paid
                                                            </TableCell>
                                                            <TableCell align="center" padding={[1.5, 6, 1.5, 6]}>
                                                                ${reservation.payment}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell width="100%" padding={[1.5, 3, 1.5, 0.5]}>
                                                                Rest
                                                            </TableCell>
                                                            <TableCell align="center" padding={[1.5, 6, 1.5, 6]}>
                                                                ${(reservation.total - reservation.payment).toFixed(2)}
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </MDBox>
                                        </MDBox>
                                    </Card>
                                </Grid>
                                <Grid item sm={12} md={4}>
                                    {
                                        reservation.status === 'pending' &&
                                        <Card sx={{marginBottom: 2}}>
                                            <MDBox sx={{padding: 2}}>
                                                <MDTypography variant="h5">Reservation Actions</MDTypography>
                                                <MDTypography variant="body2">Chat with your customer to ask any details you may need.</MDTypography>
                                                <MDBox mt={2}>
                                                    <MDButton variant="contained" color="secondary" fullWidth onClick={() => updateStatus('accepted')}>
                                                        <Icon style={{marginRight: 8}}>check</Icon>
                                                        Accept
                                                    </MDButton>
                                                </MDBox>
                                                <MDBox mt={2}>
                                                    <MDButton variant="outlined" color="error" fullWidth onClick={() => updateStatus('rejected')}>
                                                        <Icon style={{marginRight: 8}}>close</Icon>
                                                        Reject
                                                    </MDButton>
                                                </MDBox>
                                            </MDBox>
                                        </Card>
                                    }
                                    {
                                        reservation.status !== 'rejected' &&
                                        <Card>
                                            <MDBox sx={{padding: 2}}>
                                                <MDTypography variant="h5">Customer Details</MDTypography>
                                                <MDTypography variant="body2">Chat with your customer to ask any details you may need.</MDTypography>
                                                <MDBox mt={2}>
                                                    <MDTypography variant="button" fontWeight="bold">Name</MDTypography>
                                                    <MDTypography variant="subtitle2">{reservation.user.name}</MDTypography>
                                                </MDBox>
                                                <MDBox mt={2}>
                                                    <MDTypography variant="button" fontWeight="bold">Email</MDTypography>
                                                    <MDTypography
                                                        variant="subtitle2">{reservation.user.email}</MDTypography>
                                                </MDBox>
                                                <MDBox mt={2}>
                                                    <MDButton variant="outlined" color="secondary" fullWidth onClick={() => navigate(`/reservations/${id}/chat`)}>
                                                        <Icon style={{marginRight: 8}}>message</Icon>
                                                        View Chat
                                                    </MDButton>
                                                </MDBox>
                                            </MDBox>
                                        </Card>
                                    }
                                </Grid>
                            </Grid>
                    }
                </MDBox>
            </MDBox>
            <MDSnackbar
                color={snackbarConfig.color}
                icon={snackbarConfig.icon}
                title={snackbarConfig.title}
                content={snackbarConfig.message}
                dateTime=""
                autoHideDuration={3000}
                open={show}
                close={toggleSnackbar}
            />
            <Footer/>
        </DashboardLayout>
    );
};

export default ReservationDetails;
