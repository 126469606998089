/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @material-ui core components
import Card from "@mui/material/Card";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// Data
import {Form, Formik} from "formik";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import BasicInfoForm from "./components/BasicInfoForm";
import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import axios from 'axios';
import MDSnackbar from "../../../../components/MDSnackbar";
import MDButton from "../../../../components/MDButton";
import {API_URL} from "../../../../config";

// Settings page components

function BasicInfo() {

    const dispatch = useDispatch();

    const {formId, formField} = form;
    const currentValidation = validations;

    const [values, setValues] = useState(initialValues);
    const [cities, setCities] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    useEffect(() => {

        const init = async () => {
            await loadCities();
            await loadRecord();
        };

        init();

    }, []);

    const loadRecord = async () => {

        setIsLoading(true);

        try {

            const response = await axios.get(`${API_URL}/users/profile`);
            setValues(response.data);


        } catch (error) {

            setSnackbarConfig({
                message: "Something went wrong loading the profile's details",
                icon: 'cancel',
                title: 'Profile',
                color: 'warning'
            });

            setShow(true);

        }

        // Esconder indicador de carga
        setIsLoading(false);

    };

    const loadCities = async () => {

        setIsLoading(true);

        try {

            const response = await axios.get(`${API_URL}/cities`, {
                params: {
                    sort: 'name',
                    direction: 'asc',
                    active: true
                }
            });

            if(response.data.length > 0)
                setCities(response.data.map(city => ({id: city.id, label: city.name})));

        } catch (error) {

            setSnackbarConfig({
                message: "Something went wrong loading the cities",
                icon: 'cancel',
                title: 'Cities',
                color: 'warning'
            });

            setShow(true);

        }

        // Esconder indicador de carga
        setIsLoading(false);

    };

    const submitForm = async (values, actions) => {

        try {

            // Enviar datos a API
            const response = await axios.put(
                `${API_URL}/users/profile`,
                {...values}
            );

            setSnackbarConfig({
                message: 'Profile updated successfully',
                icon: 'notifications',
                title: 'Profile',
                color: 'secondary'
            });

            setShow(true);

            // dispatch(signIn(response.data.user.id, response.data.user.name, response.data.role.id));

        } catch (error) {

            setSnackbarConfig({
                message: error.response.data.message,
                icon: 'cancel',
                title: 'Profile',
                color: 'warning'
            });

            setShow(true);

        }

        actions.setSubmitting(false);

    };

    const handleSubmit = (values, actions) => {
        submitForm(values, actions);
    };

    return <>
        <Card id="basic-info" sx={{overflow: "visible"}}>
            {
                isLoading ?
                    <MDBox mt={2} mx={2} p={10} display="flex" justifyContent="center" alignItems="center">
                        <CircularProgress color="secondary" style={{overflow: 'hidden'}}/>
                    </MDBox> :
                    <Formik
                        initialValues={values}
                        validationSchema={currentValidation}
                        onSubmit={handleSubmit}
                    >
                        {({values, errors, touched, isSubmitting}) => (
                            <Form id={formId} autoComplete="off">

                                <MDBox p={3}>
                                    <MDTypography variant="h5">Basic Info</MDTypography>
                                </MDBox>
                                <MDBox pb={3} px={3}>
                                    <BasicInfoForm cities={cities} formData={{values, touched, formField, errors}}/>
                                    <MDBox mt={2} width="100%" display="flex"
                                           justifyContent="flex-end">
                                        <MDBox>
                                            <MDButton
                                                disabled={isSubmitting}
                                                variant="gradient"
                                                color="secondary"
                                                size="small"
                                                type="submit"
                                            >
                                                {isSubmitting &&
                                                <CircularProgress size={18} color="white" sx={{marginRight: 1}}/>}
                                                update profile
                                            </MDButton>
                                        </MDBox>
                                    </MDBox>
                                </MDBox>

                            </Form>
                        )}
                    </Formik>
            }
        </Card>
        <MDSnackbar
            color={snackbarConfig.color}
            icon={snackbarConfig.icon}
            title={snackbarConfig.title}
            content={snackbarConfig.message}
            dateTime=""
            autoHideDuration={3000}
            open={show}
            close={toggleSnackbar}
        />
    </>;
}

export default BasicInfo;
