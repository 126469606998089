/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @material-ui core components
import Card from "@mui/material/Card";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// Data
import {Form, Formik} from "formik";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import validations from "./schemas/validations";
import form from "./schemas/form";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import axios from 'axios';
import MDSnackbar from "../../../../../components/MDSnackbar";
import MDButton from "../../../../../components/MDButton";
import {API_URL} from "../../../../../config";
import BasicInfoForm from "./components/BasicInfoForm";

// Settings page components

function BasicInfo({name, shortDescription, description, adventureId}) {

    const authState = useSelector((state) => state.authReducer);

    const dispatch = useDispatch();

    const {formId, formField} = form;
    const currentValidation = validations;

    const [values, setValues] = useState({name, short_description: shortDescription, description});

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    const submitForm = async (values, actions) => {

        try {

            // Enviar datos a API
            const response = await axios.put(
                `${API_URL}/adventures/${adventureId}`,
                {...values}
            );

            setSnackbarConfig({
                message: 'Adventure profile updated successfully',
                icon: 'notifications',
                title: 'Adventure Profile',
                color: 'secondary'
            });

            setShow(true);

        } catch (error) {

            setSnackbarConfig({
                message: error.response.data.message,
                icon: 'cancel',
                title: 'Adventure Profile',
                color: 'warning'
            });

            setShow(true);

        }

        actions.setSubmitting(false);

    };

    const handleSubmit = (values, actions) => {
        submitForm(values, actions);
    };

    return <>
        <Card id="basic-info" sx={{overflow: "visible"}}>
            <Formik
                initialValues={values}
                validationSchema={currentValidation}
                onSubmit={handleSubmit}
            >
                {({values, errors, touched, isSubmitting}) => (
                    <Form id={formId} autoComplete="off">

                        <MDBox p={3}>
                            <MDTypography variant="h5">Basic Info</MDTypography>
                            <MDTypography variant="body2">This is your adventure's basic information. Add a short and full description to inform your visitors what can they expect.</MDTypography>
                        </MDBox>
                        <MDBox pb={3} px={3}>
                            <BasicInfoForm formData={{values, touched, formField, errors}}/>
                            <MDBox mt={2} width="100%" display="flex"
                                   justifyContent="flex-end">
                                <MDBox>
                                    <MDButton
                                        disabled={isSubmitting}
                                        variant="gradient"
                                        color="secondary"
                                        size="small"
                                        type="submit"
                                    >
                                        {isSubmitting &&
                                        <CircularProgress size={18} color="white" sx={{marginRight: 1}}/>}
                                        update profile
                                    </MDButton>
                                </MDBox>
                            </MDBox>
                        </MDBox>

                    </Form>
                )}
            </Formik>
        </Card>
        <MDSnackbar
            color={snackbarConfig.color}
            icon={snackbarConfig.icon}
            title={snackbarConfig.title}
            content={snackbarConfig.message}
            dateTime=""
            autoHideDuration={3000}
            open={show}
            close={toggleSnackbar}
        />
    </>;
}

export default BasicInfo;
