/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import MDBox from "../../../../../components/MDBox";
import FormField from "../../../../../components/FormField";
import React from "react";
import MDTypography from "../../../../../components/MDTypography";
// Material Dashboard 2 PRO React components


function NewCityForm({formData, states}) {

    const {formField, values, errors, touched} = formData;

    const {name, latitude, longitude, state_id} = formField;
    const {
        name: nameV,
        latitude: latitudeV,
        longitude: longitudeV,
        state_id: stateIdV
    } = values;

    return (
        <MDBox>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={name.type}
                        label={name.label}
                        name={name.name}
                        value={nameV}
                        error={errors.name && touched.name}
                        success={nameV.length > 0 && !errors.name}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={latitude.type}
                        label={latitude.label}
                        name={latitude.name}
                        value={latitudeV}
                        error={errors.latitude && touched.latitude}
                        success={latitudeV.length > 0 && !errors.latitude}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={longitude.type}
                        label={longitude.label}
                        name={longitude.name}
                        value={longitudeV}
                        error={errors.longitude && touched.longitude}
                        success={longitudeV.length > 0 && !errors.longitude}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={state_id.type}
                        label={state_id.label}
                        name={state_id.name}
                        value={stateIdV}
                        options={states}
                        error={errors.state_id && touched.state_id}
                        success={stateIdV && stateIdV.length > 0 && !errors.state_id}
                    />
                </Grid>
            </Grid>
            <MDTypography variant="caption">You can find latitude and longitude at (https://www.latlong.net/)</MDTypography>
        </MDBox>
    );
}

export default NewCityForm;
