/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import {Form, Formik} from "formik";
import React, {useEffect, useState} from "react";
import initialValues from "./schemas/initialValues";
import form from "./schemas/form";
import validations from "./schemas/validations";
import EditStateForm from "./components/EditStateForm";
import axios from 'axios';
import BaseLayout from "../components/BaseLayout";
import {useParams} from 'react-router-dom';
import {API_URL} from "../../../config";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDButton from "../../../components/MDButton";
import MDSnackbar from "../../../components/MDSnackbar";

// Settings page components

function EditState() {

    const { id } = useParams();

    const {formId, formField} = form;
    const currentValidation = validations;

    const [values, setValues] = useState(initialValues);

    const [isLoading, setIsLoading] = useState(true);

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    useEffect(() => {

        const init = async () => {
            await loadRecord();
        };

        init();

    }, []);

    const loadRecord = async () => {

        setIsLoading(true);

        try {

            const response = await axios.get(`${API_URL}/states/${id}`);

            setValues(response.data);

        } catch (error) {

            setSnackbarConfig({
                message: "Something went wrong loading the state's details",
                icon: 'cancel',
                title: 'States',
                color: 'warning'
            });

            setShow(true);

        }

        // Esconder indicador de carga
        setIsLoading(false);

    };

    const handleSubmit = async (values, actions) => {

        try {

            // Enviar datos a API
            await axios.put(
                `${API_URL}/states/${id}`,
                {...values}
            );

            setSnackbarConfig({
                message: 'State updated successfully',
                icon: 'notifications',
                title: 'States',
                color: 'secondary'
            });

            setShow(true);

        } catch (error) {

            setSnackbarConfig({
                message: error.response.data.message,
                icon: 'cancel',
                title: 'States',
                color: 'warning'
            });

            setShow(true);

        }

        actions.setSubmitting(false);

    };

    return (
        <BaseLayout>
            <Grid container justifyContent="center">
                <Grid item xs={12} lg={8}>
                    <MDBox mb={3}>
                        <Card id="edit-state" sx={{overflow: "visible"}}>
                            {
                                isLoading ?
                                    <MDBox mt={2} mx={2} p={10} display="flex" justifyContent="center"
                                           alignItems="center">
                                        <CircularProgress color="secondary" style={{overflow: 'hidden'}}/>
                                    </MDBox> :
                                    <Formik
                                        initialValues={values}
                                        validationSchema={currentValidation}
                                        onSubmit={handleSubmit}
                                    >
                                        {({values, errors, touched, isSubmitting}) => (
                                            <Form id={formId} autoComplete="off">
                                                <MDBox p={3}>
                                                    <MDTypography variant="h5">Edit State</MDTypography>
                                                    <MDTypography variant="body2">Update the state's
                                                        information.</MDTypography>
                                                </MDBox>
                                                <MDBox pb={3} px={3}>
                                                    <EditStateForm formData={{values, touched, formField, errors}}/>
                                                    <MDBox mt={2} width="100%" display="flex"
                                                           justifyContent="flex-end">
                                                        <MDBox>
                                                            <MDButton
                                                                disabled={isSubmitting}
                                                                variant="gradient"
                                                                color="secondary"
                                                                type="submit"
                                                            >
                                                                {isSubmitting &&
                                                                <CircularProgress size={18} color="white"
                                                                                  sx={{marginRight: 1}}/>}
                                                                Update
                                                            </MDButton>
                                                        </MDBox>
                                                    </MDBox>
                                                </MDBox>

                                            </Form>
                                        )}
                                    </Formik>
                            }
                        </Card>
                    </MDBox>
                </Grid>
            </Grid>
            <MDSnackbar
                color={snackbarConfig.color}
                icon={snackbarConfig.icon}
                title={snackbarConfig.title}
                content={snackbarConfig.message}
                dateTime=""
                autoHideDuration={3000}
                open={show}
                close={toggleSnackbar}
            />
        </BaseLayout>
    );
}

export default EditState;
