/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav.
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
 */

// Material Dashboard 2 PRO React layouts
// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";
// @mui icons
import Icon from "@mui/material/Icon";
// Images
import profilePicture from "assets/images/team-3.jpg";
import React from "react";
import Preys from "./containers/preys";
import States from "./containers/states";
import Cities from "./containers/cities";
import Adventures from "./containers/adventures/view/Adventures";
import Reservations from "./containers/reservations/view/Reservations";
import Users from "./containers/users/view/Users";
import Profile from "./containers/profile";
import AdventureDetails from "./containers/adventures/details/AdventureDetails";
import Types from "./containers/types";
import ReservationsCalendar from "./containers/reservations/calendar";

export const adminRoutes = [
  {
    type: "collapse",
    name: null,
    key: "profile",
    route: '/profile',
    icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
    noCollapse: true,
    component: <Profile />
  },
  { type: "divider", key: "divider-0" },
  { type: "title", title: "Data", key: "title-data" },
  {
    type: "collapse",
    name: "Adventures",
    key: "adventures",
    route: "/adventures",
    component: <Adventures />,
    icon: <Icon fontSize="medium">flight</Icon>,
    noCollapse: true
  },
  {
    type: "collapse",
    name: "Reservations",
    key: "reservations",
    route: "/reservations",
    component: <Reservations />,
    icon: <Icon fontSize="medium">book</Icon>,
    noCollapse: true
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <Icon fontSize="medium">person</Icon>,
    collapse: [
      {
        name: "Admins",
        key: "admins",
        route: "/users/admins",
        component: <Users roleId={1} />,
      },
      {
        name: "Outfitters",
        key: "outfitters",
        route: "/users/outfitters",
        component: <Users roleId={2} />,
      },
      {
        name: "Users",
        key: "users",
        route: "/users/users",
        component: <Users roleId={3} />,
      },
    ]
  },
  { type: "title", title: "Catalogs", key: "title-catalogs" },
  {
    type: "collapse",
    name: "Game",
    key: "preys",
    icon: <Icon fontSize="medium">pets</Icon>,
    collapse: [
      {
        name: "Game",
        key: "preys",
        route: "/preys",
        component: <Preys />,
      },
      {
        name: "Types",
        key: "types",
        route: "/types",
        component: <Types />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Locations",
    key: "locations",
    icon: <Icon fontSize="medium">flag</Icon>,
    collapse: [
      {
        name: "Cities",
        key: "cities",
        route: "/cities",
        component: <Cities />,
      },
      {
        name: "States",
        key: "states",
        route: "/states",
        component: <States />,
      },
    ],
  },
  { type: "divider", key: "divider-1" },
];

export const outfitterRoutes = [
  {
    type: "collapse",
    name: null,
    key: "profile",
    route: '/profile',
    icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
    noCollapse: true,
    component: <Profile />
  },
  { type: "divider", key: "divider-0" },
  { type: "title", title: "Data", key: "title-data" },
  {
    type: "collapse",
    name: "Adventure Details",
    key: "adventures",
    route: "/adventure-details",
    component: <AdventureDetails />,
    icon: <Icon fontSize="medium">flight</Icon>,
    noCollapse: true
  },
  {
    type: "collapse",
    name: "Reservations",
    key: "reservations",
    route: "/reservations",
    component: <Reservations />,
    icon: <Icon fontSize="medium">book</Icon>,
    noCollapse: true
  },
  {
    type: "collapse",
    name: "Calendar",
    key: "calendar",
    route: "/calendar",
    component: <ReservationsCalendar />,
    icon: <Icon fontSize="medium">calendar_today</Icon>,
    noCollapse: true
  },
  { type: "divider", key: "divider-1" },
];
