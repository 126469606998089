/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import FormField from "../../../../../../components/FormField";


function BasicInfoForm({formData, cities}) {

    const {formField, values, errors, touched} = formData;

    const {name, email, phone, address, social_security_number, id_number, city_id} = formField;
    const {
        name: nameV,
        email: emailV,
        phone: phoneV,
        address: addressV,
        social_security_number: socialSecurityNumberV,
        id_number: idNumberV,
        city_id: cityIdV
    } = values;

    return (
        <MDBox>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={name.type}
                        label={name.label}
                        name={name.name}
                        value={nameV}
                        error={errors.name && touched.name}
                        success={nameV.length > 0 && !errors.name}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={email.type}
                        label={email.label}
                        name={email.name}
                        value={emailV}
                        placeholder={email.placeholder}
                        error={errors.email && touched.email}
                        success={emailV.length > 0 && !errors.email}
                    />
                </Grid>
            </Grid>
        </MDBox>
    );
}

export default BasicInfoForm;
