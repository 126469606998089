import {useEffect, useState} from "react";
import axios from "axios";
import {API_URL} from "../../../../../config";
import {useSelector} from "react-redux";

const useProfile = (id) => {

    const authState = useSelector((state) => state.authReducer);

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    const [isLoading, setIsLoading] = useState(true);

    const [profile, setProfile] = useState(null);
    const [cities, setCities] = useState([]);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        setIsLoading(true);
        await loadProfile();
        await loadCities();
        setIsLoading(false)
    };

    const loadProfile = async () => {

        try {

            const response = await axios.get(`${API_URL}/adventures/${id}`);

            setProfile(response.data);

        } catch (error) {

            setSnackbarConfig({
                message: "Something went wrong loading the adventure's details",
                icon: 'cancel',
                title: 'Adventures',
                color: 'warning'
            });

            setShow(true);

        }

    };

    const loadCities = async () => {

        try {

            const response = await axios.get(`${API_URL}/cities`, {params: {is_active: true, sort: 'name', direction: 'asc'}});

            setCities([{id: -1, label: 'Choose an option'}, ...response.data.map(city => ({id: city.id, label: city.name}))]);

        } catch (error) {

            setSnackbarConfig({
                message: "Something went wrong loading the cities",
                icon: 'cancel',
                title: 'Cities',
                color: 'warning'
            });

            setShow(true);

        }

    };

    const handleReviewClick = async () => {

        try {

            let data = {
                adventure_id: authState.adventureId
            };

            // Enviar datos a API
            await axios.post(
                `${API_URL}/adventures/review`,
                data
            );

            setSnackbarConfig({
                message: 'Review request sent successfully',
                icon: 'notifications',
                title: 'Review',
                color: 'secondary'
            });

            setShow(true);

        } catch (error) {

            setSnackbarConfig({
                message: error.response.data.message,
                icon: 'cancel',
                title: 'Review',
                color: 'warning'
            });

            setShow(true);

        }
    };

    const handlePublishClick = async () => {

        try {

            let data = {
                adventure_id: id
            };

            // Enviar datos a API
            await axios.post(
                `${API_URL}/adventures/publish`,
                data
            );

            setSnackbarConfig({
                message: 'Adventure published',
                icon: 'notifications',
                title: 'Adventure',
                color: 'secondary'
            });

            setShow(true);

        } catch (error) {

            setSnackbarConfig({
                message: error.response.data.message,
                icon: 'cancel',
                title: 'Adventure',
                color: 'warning'
            });

            setShow(true);

        }
    };
    const formatPreysData = () => {
        return profile.preys.map(prey => ({
            color: "primary",
            icon: "pets",
            name: prey.name,
            description: prey.description
        }))
    };

    const formatAmenitiesData = () => {
        return profile.amenities.map(amenity => ({
            color: "primary",
            icon: "verified",
            name: amenity.title,
            id: amenity.id
        }))
    };

    return {profile, cities, isLoading, snackbarConfig, show, init, toggleSnackbar, formatPreysData, formatAmenitiesData, handleReviewClick, handlePublishClick}
};

export default useProfile;
