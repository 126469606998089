/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React base styles
import typography from "assets/theme/base/typography";

function Footer({ company, links }) {
    const { size } = typography;

    return (
        <MDBox
            width="100%"
            display="flex"
            flexDirection={{ xs: "column", lg: "row" }}
            justifyContent="space-between"
            alignItems="center"
            px={1.5}
        >
            <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexWrap="wrap"
                color="text"
                fontSize={size.sm}
                px={1.5}
            >
                &copy; {new Date().getFullYear()}, created by
                <Link href="https://astware.com" target="_blank">
                    <MDTypography variant="button" fontWeight="medium" ml={0.5}>
                        Mktin Ideas
                    </MDTypography>
                </Link>
            </MDBox>
        </MDBox>
    );
}

// Setting default values for the props of Footer
Footer.defaultProps = {
    company: { href: "https://www.creative-tim.com/", name: "Creative Tim" },
    links: [
        { href: "https://www.creative-tim.com/", name: "Creative Tim" },
        { href: "https://www.creative-tim.com/presentation", name: "About Us" },
        { href: "https://www.creative-tim.com/blog", name: "Blog" },
        { href: "https://www.creative-tim.com/license", name: "License" },
    ],
};

// Typechecking props for the Footer
Footer.propTypes = {
    company: PropTypes.objectOf(PropTypes.string),
    links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;
