import React from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import { withStyles } from "tss-react/mui";

const defaultToolbarStyles = {
    iconButton: {
    },
};

const CustomToolbar = ({ classes, canAdd = true, handleNewClick }) => {

    return (
        <React.Fragment>
            {
                canAdd && <Tooltip title={"Add New"}>
                    <IconButton className={classes.iconButton} onClick={handleNewClick}>
                        <AddIcon className={classes.deleteIcon} color="secondary"/>
                    </IconButton>
                </Tooltip>
            }
        </React.Fragment>
    );

};

export default withStyles(CustomToolbar, defaultToolbarStyles, { name: "CustomToolbar" });
