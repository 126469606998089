import {useEffect, useState} from "react";
import axios from "axios";
import {API_URL} from "../../../config";
import {useSelector} from "react-redux";
import form from "./schemas/form";

const useSelectPreys = (id) => {

    const authState = useSelector((state) => state.authReducer);

    const {formId, formField} = form;

    const [isLoading, setIsLoading] = useState(true);

    const [preys, setPreys] = useState([]);
    const [adventurePreys, setAdventurePreys] = useState([]);

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    useEffect(() => {

        const init = async () => {
            setIsLoading(true);
            await loadPreys();
            await loadAdventurePreys();
            setIsLoading(false);
        };

        init();
    }, []);

    const loadPreys = async () => {

        const options = {
            params: {
                sort: 'name',
                direction: 'asc'
            }
        };

        const response = await axios.get(`${API_URL}/preys`, options);

        setPreys(response.data);

    };

    const loadAdventurePreys = async () => {

        const response = await axios.get(`${API_URL}/adventures/${authState.roleId === 1 ? id : authState.adventureId}`);

        setAdventurePreys(response.data.preys.map(prey => prey.id));

    };

    const handleSubmit = async (values, actions) => {

        try {

            // Enviar datos a API
            await axios.put(
                `${API_URL}/adventures/${authState.roleId === 1 ? id : authState.adventureId}/preys`,
                {...values}
            );

            setSnackbarConfig({
                message: 'Game updated successfully',
                icon: 'notifications',
                title: 'Adventure Game',
                color: 'secondary'
            });

            setShow(true);

        } catch (error) {

            setSnackbarConfig({
                message: error.response.data.message,
                icon: 'cancel',
                title: 'Adventure Game',
                color: 'warning'
            });

            setShow(true);

        }

        actions.setSubmitting(false);

    };

    return {
        preys,
        adventurePreys,
        formId,
        isLoading,
        snackbarConfig,
        show,
        handleSubmit,
        toggleSnackbar
    }
};

export default useSelectPreys;
