
import React, {useEffect, useState} from "react";
import FormGroup from "@mui/material/FormGroup/FormGroup";
import axios from "axios";
import moment from "moment";
import Chip from "@mui/material/Chip";
import Icon from "@mui/material/Icon";
import {API_URL} from "../../../../../config";
import {capitalizeFirstLetter} from "../../../../../helpers/helpers";
import MDTypography from "../../../../../components/MDTypography";
import MDInput from "../../../../../components/MDInput";
import MDButton from "../../../../../components/MDButton";
import CustomToolbar from "../../../../../components/CustomToolbar";
import CustomToolbarSelect from "../../../../../components/CustomToolbarSelect";

const useReservations = (id) => {

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    const [endpoint, setEndpoint] = useState(`${API_URL}/reservations`);

    const [search, setSearch] = useState('');
    const [filters, setFilters] = useState(null);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [sortOrder, setSortOrder] = useState({
        name: 'created_at',
        direction: 'desc',
    });

    const [isLoading, setIsLoading] = useState(true);

    const [data, setData] = useState([['Loading data...']]);

    const [columns, setColumns] = useState([
        {
            name: 'id',
            label: 'ID',
            options: {
                display: 'excluded'
            }
        },
        {
            name: 'user.name',
            label: 'User'
        },
        {
            name: 'payment',
            label: 'Fee',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => (`$${value}`)
            }
        },
        {
            name: 'total',
            label: 'Total',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => (`$${value}`)
            }
        },
        {
            name: 'status',
            label: 'Status',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => renderStatusChip(value),
                filterType: 'dropdown',
                filterOptions: {
                    names: ['open', 'waiting', 'approved', 'cancelled', 'rejected', 'completed'],
                    renderValue: v => capitalizeFirstLetter(v)
                },
                customFilterListOptions: {
                    render: v => capitalizeFirstLetter(v)
                }
            }
        },
        {
            name: 'start_at',
            label: 'From',
            options: {
                display: 'hidden',
                customBodyRender: (value, tableMeta, updateValue) => moment(value).format('YYYY-MM-DD'),
                filter: true,
                filterType: 'custom',
                customFilterListOptions: {
                    render: v => {
                        if (v[0] && v[1]) {
                            return `From: ${v[0]}, To: ${v[1]}`;
                        } else if (v[0]) {
                            return `From: ${v[0]}`;
                        } else if (v[1]) {
                            return `To: ${v[1]}`;
                        }
                        return false;
                    },
                    update: (filterList, filterPos, index) => {

                        if (filterPos === 0) {
                            filterList[index].splice(filterPos, 1, '');
                        } else if (filterPos === 1) {
                            filterList[index].splice(filterPos, 1);
                        } else if (filterPos === -1) {
                            filterList[index] = [];
                        }

                        let newFilters = () => (filterList);
                        handleFilterSubmit(newFilters);

                        // return filterList;
                    },
                },
                filterOptions: {
                    names: [],
                    logic(date, filters) {
                        if (filters[0] && filters[1]) {
                            return date < filters[0] || date > filters[1];
                        } else if (filters[0]) {
                            return date < filters[0];
                        } else if (filters[1]) {
                            return date > filters[1];
                        }
                        return false;
                    },
                    display: (filterList, onChange, index, column) => (
                        <div>
                            <MDTypography variant="button" fontWeight="regular">Created At</MDTypography>
                            <FormGroup row style={{marginTop: 10}}>
                                <MDInput
                                    label="From"
                                    type="date"
                                    variant="standard"
                                    value={filterList[index][0] || ''}
                                    onChange={event => {
                                        filterList[index][0] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}
                                    style={{ width: '45%', marginRight: '5%' }}
                                />
                                <MDInput
                                    label="To"
                                    type="date"
                                    variant="standard"
                                    value={filterList[index][1] || ''}
                                    onChange={event => {
                                        filterList[index][1] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}
                                    style={{ width: '50%' }}
                                />
                            </FormGroup>
                        </div>
                    ),
                },
            }
        },
        {
            name: 'end_at',
            label: 'To',
            options: {
                display: 'hidden',
                customBodyRender: (value, tableMeta, updateValue) => moment(value).format('YYYY-MM-DD'),
                filter: true,
                filterType: 'custom',
                customFilterListOptions: {
                    render: v => {
                        if (v[0] && v[1]) {
                            return `From: ${v[0]}, To: ${v[1]}`;
                        } else if (v[0]) {
                            return `From: ${v[0]}`;
                        } else if (v[1]) {
                            return `To: ${v[1]}`;
                        }
                        return false;
                    },
                    update: (filterList, filterPos, index) => {

                        if (filterPos === 0) {
                            filterList[index].splice(filterPos, 1, '');
                        } else if (filterPos === 1) {
                            filterList[index].splice(filterPos, 1);
                        } else if (filterPos === -1) {
                            filterList[index] = [];
                        }

                        let newFilters = () => (filterList);
                        handleFilterSubmit(newFilters);

                        // return filterList;
                    },
                },
                filterOptions: {
                    names: [],
                    logic(date, filters) {
                        if (filters[0] && filters[1]) {
                            return date < filters[0] || date > filters[1];
                        } else if (filters[0]) {
                            return date < filters[0];
                        } else if (filters[1]) {
                            return date > filters[1];
                        }
                        return false;
                    },
                    display: (filterList, onChange, index, column) => (
                        <div>
                            <MDTypography variant="button" fontWeight="regular">Created At</MDTypography>
                            <FormGroup row style={{marginTop: 10}}>
                                <MDInput
                                    label="From"
                                    type="date"
                                    variant="standard"
                                    value={filterList[index][0] || ''}
                                    onChange={event => {
                                        filterList[index][0] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}
                                    style={{ width: '45%', marginRight: '5%' }}
                                />
                                <MDInput
                                    label="To"
                                    type="date"
                                    variant="standard"
                                    value={filterList[index][1] || ''}
                                    onChange={event => {
                                        filterList[index][1] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}
                                    style={{ width: '50%' }}
                                />
                            </FormGroup>
                        </div>
                    ),
                },
            }
        },
        {
            name: 'created_at',
            label: 'Created at',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => moment(value).format('YYYY-MM-DD'),
                filter: true,
                filterType: 'custom',
                customFilterListOptions: {
                    render: v => {
                        if (v[0] && v[1]) {
                            return `From: ${v[0]}, To: ${v[1]}`;
                        } else if (v[0]) {
                            return `From: ${v[0]}`;
                        } else if (v[1]) {
                            return `To: ${v[1]}`;
                        }
                        return false;
                    },
                    update: (filterList, filterPos, index) => {

                        if (filterPos === 0) {
                            filterList[index].splice(filterPos, 1, '');
                        } else if (filterPos === 1) {
                            filterList[index].splice(filterPos, 1);
                        } else if (filterPos === -1) {
                            filterList[index] = [];
                        }

                        let newFilters = () => (filterList);
                        handleFilterSubmit(newFilters);

                        // return filterList;
                    },
                },
                filterOptions: {
                    names: [],
                    logic(date, filters) {
                        if (filters[0] && filters[1]) {
                            return date < filters[0] || date > filters[1];
                        } else if (filters[0]) {
                            return date < filters[0];
                        } else if (filters[1]) {
                            return date > filters[1];
                        }
                        return false;
                    },
                    display: (filterList, onChange, index, column) => (
                        <div>
                            <MDTypography variant="button" fontWeight="regular">Created At</MDTypography>
                            <FormGroup row style={{marginTop: 10}}>
                                <MDInput
                                    label="From"
                                    type="date"
                                    variant="standard"
                                    value={filterList[index][0] || ''}
                                    onChange={event => {
                                        filterList[index][0] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}
                                    style={{ width: '45%', marginRight: '5%' }}
                                />
                                <MDInput
                                    label="To"
                                    type="date"
                                    variant="standard"
                                    value={filterList[index][1] || ''}
                                    onChange={event => {
                                        filterList[index][1] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}
                                    style={{ width: '50%' }}
                                />
                            </FormGroup>
                        </div>
                    ),
                },
            }
        },
    ]);

    useEffect(() => {
        getData(endpoint);
    }, []);

    const getData = async (url) => {

        setIsLoading(true);

        const response = await loadData(url);

        setData(response.data);
        setCount(response.total);
        setPage(response.page);

        setIsLoading(false);
    };

    const loadData = async (url, config = {}) => {

        let fullConfig = {
            page,
            sortOrder,
            rowsPerPage,
            search,
            filters
        };

        if(config.page)
            fullConfig.page = config.page;

        if(config.sortOrder)
            fullConfig.sortOrder = config.sortOrder;

        if(config.rowsPerPage)
            fullConfig.rowsPerPage = config.rowsPerPage;

        if(config.search !== undefined)
            fullConfig.search = config.search;

        if(config.filters)
            fullConfig.filters = config.filters;

        const options = {
            params: {
                sort: fullConfig.sortOrder.name,
                direction: fullConfig.sortOrder.direction,
                page: fullConfig.page,
                per_page: fullConfig.rowsPerPage,
                search: fullConfig.search ? fullConfig.search : undefined,
                filters: fullConfig.filters ? fullConfig.filters : undefined,
                adventure_id: id
            }
        };

        const response = await axios.get(url, options);

        return {
            data: response.data.data,
            total: response.data.total,
            page: response.data.current_page,
        };

    };

    const sort = async (sortOrder) => {
        setIsLoading(true);

        let options = {
            sortOrder,
        };

        const response = await loadData(endpoint, options);

        setData(response.data);
        setSortOrder(sortOrder);

        setIsLoading(false);
    };

    const changePage = async (page) => {
        setIsLoading(true);

        let options = {
            page: page + 1
        };

        const response = await loadData(endpoint, options);

        setPage(response.page);
        setData(response.data);

        setIsLoading(false);
    };

    const changeRowsPerPage = async (rowsPerPage) => {
        setIsLoading(true);

        let options = {
            rowsPerPage
        };

        const response = await loadData(endpoint, options);

        setRowsPerPage(rowsPerPage);
        setData(response.data);

        setIsLoading(false);
    };

    const handleSearch = async (search) => {

        setIsLoading(true);

        let options = {
            search
        };

        const response = await loadData(endpoint, options);

        setSearch(search);
        setCount(response.total);
        setPage(response.page);
        setData(response.data);

        setIsLoading(false);

    };

    const handleFilterSubmit = async (applyFilters) => {
        let filterList = formatFilters(applyFilters(), columns);

        setIsLoading(true);

        let options = {
            filters: filterList,
        };

        const response = await loadData(endpoint, options);

        setCount(response.total);
        setPage(response.page);
        setData(response.data);
        setFilters(filterList);

        setIsLoading(false);

    };

    const handleDeleteClick = async (ids) => {

        // Mostrar el indicador de carga
        setIsLoading(true);

        try {

            // Total de seleccionados
            let count = ids.length;

            // Por cada registro seleccionado
            for(let i = 0; i < count; i++){

                // Cambiar estado
                await axios.delete(`${endpoint}/${ids[i]}`);

            }

            setSnackbarConfig({
                message: count === 1 ? `1 record updated` : `${count} records updated`,
                icon: 'notifications',
                title: 'Reservations',
                color: 'secondary'
            });

            setShow(true);

            const response = await loadData(endpoint);

            setCount(response.total);
            setPage(response.page);
            setData(response.data);

        } catch (error) {

            if(error.response.data.message){
                setSnackbarConfig({
                    message: error.response.data.message,
                    icon: 'cancel',
                    title: 'Reservations',
                    color: 'warning'
                });

                setShow(true);
            } else {
                setSnackbarConfig({
                    message: 'An error occurred deactivating or activating one or more records.',
                    icon: 'cancel',
                    title: 'Reservations',
                    color: 'warning'
                });

                setShow(true);
            }
        }

        // Esconder indicador de carga
        setIsLoading(false);

    };

    const renderStatusChip = (status) => {
        switch (status) {
            case 'pending':
                return <Chip size="small" color="secondary" icon={<Icon fontSize="small">access_time</Icon>} label="Pending"
                             variant="outlined"/>;
            case 'cancelled':
                return <Chip size="small" color="warning" icon={<Icon fontSize="small">cancel</Icon>}
                             label="Cancelled" variant="outlined"/>;
        }
    };

    const options = {
        elevation: 0,
        responsive: 'vertical',
        serverSide: true,
        count: count,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [5, 10, 25, 50],
        sortOrder: sortOrder,
        download: false,
        print: false,
        enableNestedDataAccess: '.',
        filter: true,
        filterType: 'textField',
        confirmFilters: true,
        customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
            return (
                <div style={{marginTop: '40px'}}>
                    <MDButton variant="contained" onClick={() => handleFilterSubmit(applyNewFilters)}>Apply
                        Filters</MDButton>
                </div>
            );
        },
        customToolbar: () => {
            return (
                <CustomToolbar canAdd={false} />
            );
        },
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
            <CustomToolbarSelect selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows} canEdit={false} handleDeleteClick={handleDeleteClick} />
        ),
        onFilterChange: (column, filterList, type) => {
            if (type === 'chip') {
                let newFilters = () => (filterList);
                handleFilterSubmit(newFilters);
            }
        },
        onSearchChange: handleSearch,
        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    changePage(tableState.page);
                    break;
                case 'sort':
                    sort(tableState.sortOrder);
                    break;
                case 'changeRowsPerPage':
                    changeRowsPerPage(tableState.rowsPerPage);
                    break;
            }
        },
    };

    return {
        isLoading,
        data,
        columns,
        options,
        snackbarConfig,
        show,
        toggleSnackbar,
    }
};

export default useReservations;
