export const formatFilters = (list, columns) => {
    let formattedData = {};

    for(let i = 0; i < columns.length; i++){
        if(list[i].length > 0){
            formattedData[columns[i].name] = list[i];
        }
    }

    return formattedData;
};

export const renderAvatarInitials = (name) => {

    const segments = name.split(' ');

    if(segments.length >= 2)
        return segments[0][0].toUpperCase() + segments[1][0].toUpperCase();
    else
        return segments[0][0].toUpperCase();
};

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};
