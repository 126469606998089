/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React, {useEffect, useState} from "react";
// react-router-dom components
import {Link} from "react-router-dom";
// @mui material components
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// Authentication layout components
import IllustrationLayout from "containers/authentication/components/IllustrationLayout";
// Image
import bgImage from "assets/images/bg_auth.jpg";
import {Form, Formik} from "formik";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import initialValues from "./schemas/initialValues";
import validations from "./schemas/validations";
import form from "./schemas/form";
import {API_URL, APP_URL} from "../../../config";
import axios from "axios";
import MDSnackbar from "../../../components/MDSnackbar";
import {useDispatch} from "react-redux";
import SignUpForm from "./components/SignUpForm";
import {signIn} from "../../../store/actions/auth";

function SignUp() {

    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);

    const [values, setValues] = useState(initialValues);

    const {formId, formField} = form;
    const currentValidation = validations;

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    const submitForm = async (values, actions) => {

        try {

            await axios.get(`${APP_URL}/sanctum/csrf-cookie`);

            // Enviar datos a API
            const response = await axios.post(
                `${API_URL}/sign_up`,
                {...values}
            );

            actions.resetForm();

            setSnackbarConfig({
                message: 'Signed up successfully',
                icon: 'notifications',
                title: 'Authentication',
                color: 'secondary'
            });

            setShow(true);

            dispatch(signIn(response.data.user.id, response.data.user.name, response.data.role.id, response.data.adventure.id));

        } catch (error) {

            setSnackbarConfig({
                message: error.response.data.message,
                icon: 'cancel',
                title: 'Authentication',
                color: 'warning'
            });

            setShow(true);

        }

        actions.setSubmitting(false);

    };

    return (
        <IllustrationLayout
            title="Sign Up"
            description="Enter your account details to sign up"
            illustration={bgImage}
        >
            {
                !isLoading &&
                <Formik
                    initialValues={values}
                    validationSchema={currentValidation}
                    onSubmit={submitForm}
                >
                    {({values, errors, touched, isSubmitting}) => (
                        <Form id={formId} autoComplete="off">
                            <SignUpForm formData={{values, touched, formField, errors}}/>
                            <MDBox mt={4} mb={1}>
                                <MDButton
                                    disabled={isSubmitting}
                                    variant="gradient"
                                    color="secondary"
                                    size="large"
                                    fullWidth
                                    type="submit"
                                >
                                    {isSubmitting && <CircularProgress size={18} color="white" sx={{marginRight: 1}}/>}
                                    sign up
                                </MDButton>
                            </MDBox>
                            <MDBox textAlign="center">
                                <MDTypography variant="button" color="text">By signing up, you agree to our <a href="https://tekuoutdoors.com/terms-and-conditions" style={{color: '#00824E', fontWeight: "bold"}}>Terms & Conditions</a></MDTypography>
                            </MDBox>
                            <MDBox mt={3} textAlign="center">
                                <MDTypography variant="button" color="text">
                                    Already have an account?{" "}
                                    <MDTypography
                                        component={Link}
                                        to="/authentication/sign-in"
                                        variant="button"
                                        color="secondary"
                                        fontWeight="medium"
                                        textGradient
                                    >
                                        Sign in
                                    </MDTypography>
                                </MDTypography>
                            </MDBox>
                        </Form>
                    )}
                </Formik>
            }
            <MDSnackbar
                color={snackbarConfig.color}
                icon={snackbarConfig.icon}
                title={snackbarConfig.title}
                content={snackbarConfig.message}
                dateTime=""
                autoHideDuration={3000}
                open={show}
                close={toggleSnackbar}
            />
        </IllustrationLayout>
    );
}

export default SignUp;
