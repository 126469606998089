/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React, {useEffect, useState} from "react";
// react-router components
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
// @mui material components
import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
// Material Dashboard 2 PRO React components
// Material Dashboard 2 PRO React examples
import Sidenav from "components/Sidenav";
// Material Dashboard 2 PRO React themes
import theme from "assets/theme";
// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";
// RTL plugins
// Material Dashboard 2 PRO React routes
import {adminRoutes} from "routes";
// Material Dashboard 2 PRO React contexts
import {setMiniSidenav, useMaterialUIController} from "context";
// Images
import brandWhite from "assets/images/logo.png";
import brandDark from "assets/images/logo.png";
import {useDispatch, useSelector} from "react-redux";
import {API_URL} from "./config";
import axios from "axios";
import {signIn} from "./store/actions/auth";
import Loading from "./containers/loading";
import SignIn from "./containers/authentication/sign-in";
import SignUp from "./containers/authentication/sign-up";
import RecoverPassword from "./containers/authentication/recover-password";
import ResetPassword from "./containers/authentication/reset-password";
import NewPrey from "./containers/preys/new-prey";
import EditPrey from "./containers/preys/edit-prey";
import NewState from "./containers/states/new-state";
import EditState from "./containers/states/edit-state";
import NewCity from "./containers/cities/new-city";
import EditCity from "./containers/cities/edit-city";
import AdventureDetails from "./containers/adventures/details/AdventureDetails";
import Profile from "./containers/profile";
import NewUser from "./containers/users/new-user/NewUser";
import {outfitterRoutes} from "./routes";
import SelectPreys from "./containers/preys/select-preys/SelectPreys";
import NewAmenity from "./containers/amenities/new-amenity/NewAmenity";
import NewItem from "./containers/items/new-item/NewItem";
import EditItem from "./containers/items/edit-item/EditItem";
import ReservationDetails from "./containers/reservations/details/ReservationDetails";
import Chat from "./containers/chats/Chat";
import NewType from "./containers/types/new-type";
import EditType from "./containers/types/edit-type";

// Layouts

export default function App() {
  const [controller, materialDispatch] = useMaterialUIController();

  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const {pathname} = useLocation();

  const dispatch = useDispatch();

  const authState = useSelector((state) => state.authReducer);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {

    const checkUser = async () => {
      setIsLoading(true);

      try {
        const response = await axios.get(`${API_URL}/user`);

        if(response.data.role.id === 2)
          dispatch(signIn(response.data.user.id, response.data.user.name, response.data.role.id, response.data.adventure.id));
        else
          dispatch(signIn(response.data.user.id, response.data.user.name, response.data.role.id, null));

      } catch (error) {

      }

      setIsLoading(false);
    };

    checkUser();

  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(materialDispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(materialDispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
      allRoutes.map((route) => {
        if (route.collapse) {
          return getRoutes(route.collapse);
        }

        if (route.route) {
          return <Route exact path={route.route} element={route.component} key={route.key}/>;
        }

        return null;
      });

  const getRoleRoutes = (roleId) => {
    switch (roleId) {
      case 1: return adminRoutes;
      case 2: return outfitterRoutes;
    }
  };

  return <ThemeProvider theme={darkMode ? themeDark : theme}>
    <CssBaseline/>
    {
      isLoading ?
          <Loading/> :
          <>
            {
              authState.isLoggedIn ?
                  <>
                    {layout === "dashboard" && (
                        <>
                          <Sidenav
                              color={sidenavColor}
                              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                              brandName="Teku"
                              routes={getRoleRoutes(authState.roleId)}
                              onMouseEnter={handleOnMouseEnter}
                              onMouseLeave={handleOnMouseLeave}
                          />
                        </>
                    )}
                    {
                      authState.roleId === 1 &&
                      <Routes>
                        {getRoutes(adminRoutes)}
                        <Route exact path="/users/new-admin" element={<NewUser/>} key="new-admin"/>
                        <Route exact path="/types/new-type" element={<NewType/>} key="new-type"/>
                        <Route exact path="/types/:id/edit-type" element={<EditType/>} key="edits-type"/>
                        <Route exact path="/preys/new-prey" element={<NewPrey/>} key="new-prey"/>
                        <Route exact path="/preys/:id/edit-prey" element={<EditPrey/>} key="edit-prey"/>
                        <Route exact path="/states/new-state" element={<NewState/>} key="new-state"/>
                        <Route exact path="/states/:id/edit-state" element={<EditState/>} key="edit-state"/>
                        <Route exact path="/cities/new-city" element={<NewCity/>} key="new-city"/>
                        <Route exact path="/cities/:id/edit-city" element={<EditCity/>} key="edit-city"/>
                        <Route exact path="/adventures/:id" element={<AdventureDetails/>} key="adventure-details"/>
                        <Route exact path="/adventure/:id/preys" element={<SelectPreys/>} key="select-preys"/>
                        <Route exact path="/adventure/:id/new-amenity" element={<NewAmenity/>} key="new-amenity"/>
                        <Route path="*" element={<Navigate to="/reservations"/>}/>
                      </Routes>
                    }
                    {
                      authState.roleId === 2 &&
                      <Routes>
                        {getRoutes(outfitterRoutes)}
                        <Route exact path="/adventure/preys" element={<SelectPreys/>} key="select-preys"/>
                        <Route exact path="/adventure/new-amenity" element={<NewAmenity/>} key="new-amenity"/>
                        <Route exact path="/adventure/new-item" element={<NewItem/>} key="new-item"/>
                        <Route exact path="/adventure/items/:id/edit-item" element={<EditItem/>} key="edit-item"/>
                        <Route exact path="/reservations/:id" element={<ReservationDetails/>} key="reservation-details"/>
                        <Route exact path="/reservations/:id/chat" element={<Chat />} key="reservation-chat"/>
                        <Route path="*" element={<Navigate to="/reservations"/>}/>
                      </Routes>
                    }
                  </> :
                  <Routes>
                    <Route exact path="/authentication/sign-in" element={<SignIn/>}
                           key="sign-in"/>
                    <Route exact path="/authentication/sign-up" element={<SignUp/>}
                           key="sign-up"/>
                    <Route exact path="/authentication/recover-password" element={<RecoverPassword/>}
                           key="recover-password"/>
                    <Route exact path="/authentication/reset-password" element={<ResetPassword />}
                           key="reset-password"/>
                    <Route path="*" element={<Navigate to="/authentication/sign-in"/>}/>
                  </Routes>
            }
          </>
    }
  </ThemeProvider>
}
