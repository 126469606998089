import Icon from "@mui/material/Icon";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";

const RatingScore = ({score}) => {
    return <MDBox display="flex" flexDirection="row" alignItems="center">
        <Icon color="secondary">star</Icon>
        <MDTypography variant="body2">
            {score}
        </MDTypography>
    </MDBox>

};

export default RatingScore;
