/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { name, latitude, longitude, state_id },
} = checkout;

const validations = Yup.object().shape({
  [name.name]: Yup.string().required(name.errorMsg),
  [latitude.name]: Yup.string().required(latitude.errorMsg),
  [longitude.name]: Yup.string().required(longitude.errorMsg),
  [state_id.name]: Yup.string().nullable().required(state_id.errorMsg),
});

export default validations;
