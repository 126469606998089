import React from "react";
import {Form, Formik} from "formik";
import Grid from "@mui/material/Grid";
import MDBox from "../../../components/MDBox";
import Card from "@mui/material/Card";
import BaseLayout from "../../../components/BaseLayout";
import MDSnackbar from "../../../components/MDSnackbar";
import useNewItem from "./useNewItem";
import initialValues from "./schemas/initialValues";
import MDTypography from "../../../components/MDTypography";
import MDButton from "../../../components/MDButton";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import NewItemForm from "./components/NewItemForm/NewItemForm";

const NewItem = () => {

    const {formId, formField, currentValidation, snackbarConfig, show, handleSubmit, toggleSnackbar} = useNewItem();

    return (
        <BaseLayout>
            <Grid container justifyContent="center">
                <Grid item xs={12} lg={8}>
                    <MDBox mb={3}>
                        <Card id="new-item" sx={{overflow: "visible"}}>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={currentValidation}
                                onSubmit={handleSubmit}
                            >
                                {({values, errors, touched, isSubmitting}) => (
                                    <Form id={formId} autoComplete="off">
                                        <MDBox p={3}>
                                            <MDTypography variant="h5">New Item</MDTypography>
                                            <MDTypography variant="body2">Enter the new item details.</MDTypography>
                                        </MDBox>
                                        <MDBox pb={3} px={3}>
                                            <NewItemForm formData={{values, touched, formField, errors}}/>
                                            <MDBox mt={2} width="100%" display="flex"
                                                   justifyContent="flex-end">
                                                <MDBox>
                                                    <MDButton
                                                        disabled={isSubmitting}
                                                        variant="gradient"
                                                        color="secondary"
                                                        type="submit"
                                                    >
                                                        {isSubmitting &&
                                                        <CircularProgress size={18} color="white"
                                                                          sx={{marginRight: 1}}/>}
                                                        Save
                                                    </MDButton>
                                                </MDBox>
                                            </MDBox>
                                        </MDBox>
                                    </Form>
                                )}
                            </Formik>
                        </Card>
                    </MDBox>
                </Grid>
            </Grid>
            <MDSnackbar
                color={snackbarConfig.color}
                icon={snackbarConfig.icon}
                title={snackbarConfig.title}
                content={snackbarConfig.message}
                dateTime=""
                autoHideDuration={3000}
                open={show}
                close={toggleSnackbar}
            />
        </BaseLayout>
    );
};

export default NewItem;
